import React, { useContext } from "react"
import styled from "styled-components"
import { AuthContext } from "../../contexts/AuthContext"
import { UnitType } from "../../contexts/CRMContext"
import { FavoritesContext } from "../../contexts/FavoritesContext"
import { PixelStreamingContext } from "../../contexts/PixelStreamingContext"
import { UnitFiltersContext } from "../../contexts/UnitFiltersContext"
import { FavoriteIdentifier } from "../../hooks/useFavoritesReducer"
import { formatPrice } from "../../utils"
import { Icon, IconType } from "../Icon"
const Container = styled.div``

const Table = styled.table`
  width: 100%;
  border: none;
  border-spacing: 0px 20px;
  padding: 0px 12px;
`
const HeaderCell = styled.th`
  font-size: 16px;
  font-weight: 300;
  color: #0d4657;
  position: sticky;
  background-color: rgba(255, 246, 241, 1);
  top: 0;
`

const StyledUnitRow = styled.tr`
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
`
const UnitCell = styled.td<{ accentColor?: string }>`
  text-align: center;
  padding: 8px;
  background-color: rgba(6, 74, 95, 1);
  color: white;

  border-right: 1px solid rgba(0, 0, 0, 0.3);

  :first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  :last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-right: ${(props) => (props.accentColor ? `6px solid ${props.accentColor}` : "none")};
  }
`
type UnitRowProps = {
  unit: UnitType
  showPrice: boolean
}

const colorForStatus = (status: string) => {
  switch (status) {
    case "Disponible":
      return "green"
    case "Réservé":
      return "orange"
    case "Vendu":
      return "#FF3B30"
    default:
      return "gray"
  }
}

const UnitRow = (props: UnitRowProps): JSX.Element => {
  const { unit, showPrice } = props
  const { id, phase, floor, bedrooms, surface, price, status } = unit
  const pixelCtx = useContext(PixelStreamingContext)
  const { favorites, toggleFavorite } = useContext(FavoritesContext)
  const favoriteID: FavoriteIdentifier = `unit:${id}`
  const isFavorite = favorites.has(favoriteID)

  console.log({ showPrice })

  const handleClick = () => {
    pixelCtx.ueCommands.emitUIInteraction({
      type: "focus_unit",
      unit_id: id
    })
  }

  return (
    <StyledUnitRow onClick={handleClick}>
      <UnitCell>{id.toString()}</UnitCell>
      <UnitCell>{phase}</UnitCell>
      <UnitCell>{floor}</UnitCell>
      <UnitCell>{bedrooms === 0 ? "Studio" : bedrooms}</UnitCell>
      <UnitCell>{surface}</UnitCell>
      {showPrice && <UnitCell>{formatPrice(price)}</UnitCell>}
      <UnitCell accentColor={colorForStatus(status)}>
        <div
          style={{ display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer" }}
          onClick={(ev) => {
            ev.stopPropagation()
            toggleFavorite(favoriteID)
          }}
        >
          <Icon type={IconType.heart} size={24} color={isFavorite ? "#FF3B30" : "white"} />
        </div>
      </UnitCell>
    </StyledUnitRow>
  )
}

const UnitsList = (): JSX.Element => {
  const { showPrices } = useContext(AuthContext)
  const { filteredUnits } = useContext(UnitFiltersContext)

  return (
    <Container>
      <Table>
        <thead>
          <tr>
            <HeaderCell>Unité</HeaderCell>
            <HeaderCell>Phase</HeaderCell>
            <HeaderCell>Etage</HeaderCell>
            <HeaderCell>Chambres</HeaderCell>
            <HeaderCell>Surface</HeaderCell>
            {showPrices && <HeaderCell>Prix</HeaderCell>}
            <HeaderCell>Favoris</HeaderCell>
          </tr>
        </thead>
        <tbody>
          {filteredUnits.map((unit) => {
            return <UnitRow key={unit.id} unit={unit} showPrice={showPrices} />
          })}
        </tbody>
      </Table>
    </Container>
  )
}

export default UnitsList
