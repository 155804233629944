import React, { useContext } from "react"
import styled from "styled-components"
import { Scene } from "../../const/servers"
import { AuthContext } from "../../contexts/AuthContext"
import { CRMContext, GalleryItemType } from "../../contexts/CRMContext"
import { PixelStreamingContext } from "../../contexts/PixelStreamingContext"
import { NEW_WINDOW, ShowroomWindowContext } from "../../contexts/ShowroomWindowContext"

type Amenity = {
  id: string
  title: string
} & (
  | {
      type: "ue"
    }
  | {
      type: "gallery"
      gallery_item_id: string
    }
)

const AMENITIES: Amenity[] = [
  { id: "urban_chalet", title: "Chalet Urbain", type: "ue" },
  { id: "lobby", title: "Lobby", type: "gallery", gallery_item_id: "181" },
  { id: "coworking", title: "Coworking", type: "gallery", gallery_item_id: "176" },
  { id: "pool", title: "Piscine", type: "ue" },
  { id: "gym", title: "Salle d'entrainement", type: "gallery", gallery_item_id: "173" },
  { id: "terrasse", title: "Les toits-terrasses", type: "gallery", gallery_item_id: "182" }
]

const Container = styled.div`
  background-color: rgba(6, 74, 95, 1);
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const AmenityItem = styled.div`
  background-color: #f4eeea;
  display: flex;
  justify-content: center;
  padding: 8px;
  border-radius: 8px;
  color: #0d4657;
  font-weight: 700;
  cursor: pointer;
`

const Amenities = (): JSX.Element => {
  const authCtx = useContext(AuthContext)
  const windowCtx = useContext(ShowroomWindowContext)
  const pixelCtx = useContext(PixelStreamingContext)
  const crmCtx = useContext(CRMContext)

  const handleUEAmenity = (id: string) => {
    pixelCtx.ueCommands.emitUIInteraction({
      type: "amenity_focus",
      amenity_id: id
    })

    if (authCtx.tokenData && authCtx.tokenData.scene_id !== Scene.cloud) {
      if (id === "urban_chalet") {
        windowCtx.addWindow({ ...NEW_WINDOW.teleportation, title: "Chalet Urbain" })
      }
    }
  }

  const handleGalleryAmenity = (item: GalleryItemType) => {
    windowCtx.addWindow({ ...NEW_WINDOW.gallery_viewer, title: item.title }, item)
  }

  return (
    <Container>
      {AMENITIES.map((amenity) => {
        const { id, title, type } = amenity
        if (type == "ue") {
          return (
            <AmenityItem key={id} onClick={() => handleUEAmenity(id)}>
              {title}
            </AmenityItem>
          )
        } else if (type === "gallery") {
          const item = crmCtx.maps.gallery.get(amenity.gallery_item_id)
          if (item) {
            return (
              <AmenityItem key={id} onClick={() => handleGalleryAmenity(item)}>
                {title}
              </AmenityItem>
            )
          } else {
            return null
          }
        }
      })}
    </Container>
  )
}

export default Amenities
