import React, { HTMLProps } from "react"
import styled from "styled-components"
import { InputLabel } from "./InputLabel"

const TextAreaContainer = styled.div<{ disabled?: boolean }>`
  padding: 8px;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 6px;

  display: flex;
  flex-direction: column;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`

type TextAreaProps = HTMLProps<HTMLTextAreaElement> & {
  label: string
}

export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>((props, ref) => {
  return (
    <div>
      <InputLabel disabled={props.disabled}>{props.label}</InputLabel>
      <TextAreaContainer disabled={props.disabled}>
        <textarea {...props} ref={ref} style={{ resize: "none" }}></textarea>
      </TextAreaContainer>
    </div>
  )
})

TextArea.displayName = "TextArea"
