import Amenities from "../components/Windows/Amenities"
import Around from "../components/Windows/Around"
import Favorites from "../components/Windows/Favorites"
import Gallery from "../components/Windows/Gallery"
import GalleryViewer from "../components/Windows/GalleryViewer"
import InteriorCustomization from "../components/Windows/InteriorCustomization"
import MasterFilters from "../components/Windows/MasterFilters"
import PlanViewer from "../components/Windows/PlanViewer"
import Teleportation from "../components/Windows/Teleportation"
import TemplateUnits from "../components/Windows/TemplateUnits"
import UnitsList from "../components/Windows/UnitsList"

import { AddWindowParams, createWindowManagerContext, RenderWindowMap } from "../WindowManager/WindowManagerContext"

export enum ShowroomWindows {
  Gallery = "gallery",
  Favorites = "favorites",
  Around = "around",
  MasterFilters = "filters",
  UnitsList = "units_list",
  Amenities = "amenities",
  TemplateUnits = "template_units",
  InteriorCustomization = "interior_customization",
  Teleportation = "teleportation",
  GalleryViewer = "gallery_viewer",
  PlanViewer = "plan_viewer"
}

export const RENDER_MAP: RenderWindowMap<ShowroomWindows> = {
  gallery: Gallery,
  favorites: Favorites,
  around: Around,
  filters: MasterFilters,
  units_list: UnitsList,
  amenities: Amenities,
  template_units: TemplateUnits,
  interior_customization: InteriorCustomization,
  teleportation: Teleportation,
  gallery_viewer: GalleryViewer,
  plan_viewer: PlanViewer
}

export const NEW_WINDOW: {
  [K: string]: AddWindowParams<ShowroomWindows>
} = {
  around: { family: ShowroomWindows.Around, title: "Environnant", sizing: { width: 500, height: 700 } },
  gallery: { family: ShowroomWindows.Gallery, title: "Gallerie", sizing: { width: 500, height: 600 } },
  favorites: { family: ShowroomWindows.Favorites, title: "Favoris", sizing: { width: 520, height: 600 } },
  amenities: {
    family: ShowroomWindows.Amenities,
    title: "Commodités",
    sizing: {
      width: 330,
      height: 377
    }
  },
  template_units: { family: ShowroomWindows.TemplateUnits, title: "Unités Témoins", sizing: { width: 350, height: 464 } },
  filters: { family: ShowroomWindows.MasterFilters, title: "Filtres", sizing: { width: 500, height: 448 } },
  interior_customization: {
    family: ShowroomWindows.InteriorCustomization,
    title: "Personnalisation",
    sizing: { width: 330, height: 328 }
  },
  teleportation: {
    family: ShowroomWindows.Teleportation,
    title: "Téléportation",
    sizing: { width: 350, height: 328 },
    position: { top: 500 }
  },
  units_list: { title: "Liste des unités", family: ShowroomWindows.UnitsList, sizing: { width: 500, height: 700 } },
  gallery_viewer: {
    title: "Image",
    family: ShowroomWindows.GalleryViewer,
    sizing: { width: 500, height: 500 },
    resizable: true,
    strategy: "stack"
  },
  plan_viewer: {
    title: "Image",
    family: ShowroomWindows.PlanViewer,
    sizing: { width: 504, height: 364 },
    resizable: true,
    strategy: "stack"
  }
}

export const ShowroomWindowContext = createWindowManagerContext<ShowroomWindows>()
