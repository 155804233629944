import { useEffect, useState } from "react"
import { ReactSwitchProps } from "react-switch"

export type UseSwitchGroupProps<K extends string> = {
  keys: K[]
}

export type UseSwitchGroup = {
  allChecked: boolean
  switchesValues: Map<string, boolean>
  onSwitchChange: ReactSwitchProps["onChange"]
}

export const useSwitchGroup = <K extends string>(props: UseSwitchGroupProps<K>): UseSwitchGroup => {
  const [allChecked, setAllChecked] = useState(true)
  const [switchesValues, setSwitchesValues] = useState(new Map<K, boolean>(props.keys.map((k) => [k, false])))

  const onSwitchChange: ReactSwitchProps["onChange"] = (checked, ev, id) => {
    if (id === "all") {
      setAllChecked(checked)
      setSwitchesValues((prev) => {
        return Array.from(prev.keys()).reduce((acc, key) => {
          acc.set(key, !checked)
          return acc
        }, new Map<K, boolean>())
      })
      return
    }

    setSwitchesValues((prev) => {
      const newMap = new Map(prev)
      newMap.set(id as K, checked)
      return newMap
    })
  }

  useEffect(() => {
    const allOn = Array.from(switchesValues.values()).every((val) => !!val)
    const allOff = Array.from(switchesValues.values()).every((val) => !val)
    if (allOn) setAllChecked(false)
    else if (allOff) setAllChecked(true)
    else setAllChecked(false)
  }, [switchesValues])

  return {
    allChecked,
    switchesValues,
    onSwitchChange
  }
}
