import React, { useCallback, useContext, useMemo, useState } from "react"
import styled from "styled-components"
import { z } from "zod"
import { Button } from "../components/Button"
import { Input } from "../components/Input"
import Config from "../config"
import { Scene, SERVER_OPTIONS } from "../const/servers"
import { AuthContext } from "../contexts/AuthContext"

const Wrapper = styled.div`
  background-color: black;
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Container = styled.div`
  background-color: rgba(255, 255, 255, 0.95);
  width: 400px;
  display: flex;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
`

const Header = styled.h3``

const ErrorMessage = styled.div`
  margin-top: 20px;
  color: #8d1313;
  background-color: #f5d4d4;
  padding: 8px 12px;
  border-radius: 6px;
`

const EMAIL_REGEX =
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/

const FieldWrapper = styled.div`
  margin-top: 16px;
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
`

const RadioGroup = styled.div`
  display: flex;
  gap: 20px;
`
const RadioItem = styled.div`
  > label {
    color: rgba(0, 0, 0, 0.9);
    margin-left: 8px;
  }
`

type ServerSelectionProps = {
  value: Scene
  onChange: (value: Scene) => void
}

const ServerSelection = (props: ServerSelectionProps): JSX.Element => {
  const { value } = props

  const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const scene = z.nativeEnum(Scene).parse(ev.currentTarget.value)
    props.onChange(scene)
  }

  const serverOptions = useMemo(() => {
    if (Config.REACT_APP_DEV_MODE) return SERVER_OPTIONS
    return SERVER_OPTIONS.filter((o) => o.dev_only === false)
  }, [])

  return (
    <FieldWrapper>
      <div>Serveur:</div>
      <RadioGroup>
        {serverOptions.map((o) => {
          return (
            <RadioItem key={o.scene_id}>
              <input
                type="radio"
                name="server"
                onChange={handleChange}
                id={o.scene_id}
                value={o.scene_id}
                checked={value === o.scene_id}
              />
              <label htmlFor={o.scene_id}>{o.display_name}</label>
            </RadioItem>
          )
        })}
      </RadioGroup>
    </FieldWrapper>
  )
}

type ShowPriceProps = {
  value: boolean
  onChange: (value: boolean) => void
}

const ShowPrices = (props: ShowPriceProps): JSX.Element => {
  const { value, onChange } = props

  const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    onChange(ev.currentTarget.value == "1")
  }

  return (
    <FieldWrapper>
      <div>Afficher les prix:</div>
      <RadioGroup>
        <RadioItem>
          <input type="radio" name="show_prices" id="show_prices_yes" value={1} checked={!!value} onChange={handleChange} />
          <label htmlFor="show_prices_yes">Oui</label>
        </RadioItem>
        <RadioItem>
          <input type="radio" name="show_prices" id="show_prices_no" value={0} checked={!value} onChange={handleChange} />
          <label htmlFor="show_prices_no">Non</label>
        </RadioItem>
      </RadioGroup>
    </FieldWrapper>
  )
}

const LoginPage = (): JSX.Element => {
  const authCtx = useContext(AuthContext)
  const [email, setEmail] = useState<string>("")
  const [password, setPassword] = useState<string>("")
  const [server, setServer] = useState<Scene>(Scene.cloud)
  const [showPrices, setShowPrices] = useState(authCtx.showPrices)

  const [error, setError] = useState<string | null>(null)

  const go = useCallback(async () => {
    if (!EMAIL_REGEX.test(email)) return setError("L'adresse courriel n'est pas valide.")
    try {
      authCtx.setShowPrices(showPrices)
      const result = await authCtx.login({ variables: { input: { email, password, server } } })
      if (result.errors?.length) {
        setError("La connexion a echoué. Verifiez vos informations et essayez à nouveau.")
      }
    } catch (e) {
      setError("La connexion a echoué. Verifiez vos informations et essayez à nouveau.")
    }
  }, [email, password, server])

  return (
    <Wrapper>
      <Container>
        <Header>Vivenda - Connexion</Header>
        <Input
          inverted
          label="Courriel"
          autoComplete="none"
          type="email"
          name="email"
          placeholder="john@example.com"
          value={email}
          onChange={(ev) => setEmail(ev.currentTarget.value)}
        />
        <Input
          inverted
          label="Mot de passe"
          autoComplete="none"
          type="password"
          name="password"
          value={password}
          onChange={(ev) => setPassword(ev.currentTarget.value)}
        />
        <ServerSelection value={server} onChange={(val) => setServer(val)} />
        <ShowPrices value={showPrices} onChange={(val) => setShowPrices(val)} />
        <Button intent="primary" label="Go" style={{ marginTop: "20px" }} onClick={go} />
        {!!error && <ErrorMessage>{error}</ErrorMessage>}
      </Container>
    </Wrapper>
  )
}

export default LoginPage
