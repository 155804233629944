import React, { PropsWithChildren, useContext } from "react"
import { Rnd } from "react-rnd"
import styled, { css } from "styled-components"
import { Icon, IconType } from "../components/Icon"
import { ShowroomLevelContext } from "../contexts/ShowroomLevelContext"
import { WindowHeader } from "./WindowHeader"
import { IWindow, IWindowManagerContext } from "./WindowManagerContext"

export type WindowProps<Family extends string> = {
  window: IWindow<Family>
  context: React.Context<IWindowManagerContext<Family>>
}

export type WindowWrapperProps = {
  width: number
  height?: number
  top: number
  left: number
}

export const ContentWrapper = styled.div<{ resizable?: boolean }>`
  border-radius: 0px 0px 10px 10px;
  background-color: rgba(255, 246, 241, 0.9);
  overflow-y: scroll;
  min-height: calc(100% - 57px);

  ${(props) =>
    props.resizable &&
    css`
      margin-bottom: 38px;
    `}
`

export const FooterBar = styled.div`
  background-color: rgba(6, 74, 95, 1);
  position: absolute;
  width: 100%;
  bottom: 0;
  min-height: 38px;
  border-radius: 0px 0px 8px 8px;
`
export const FooterWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 12px 8px;
`

export const Window = <Family extends string>(props: PropsWithChildren<WindowProps<Family>>): JSX.Element => {
  const { window } = props
  const { position, sizing } = window

  const windowCtx = useContext(props.context)
  const levelCtx = useContext(ShowroomLevelContext)

  const content = window.renderContent({ window })

  const loading = levelCtx.isLoadingLevel || levelCtx.isStreamingLevel

  return (
    <Rnd
      default={{
        x: position.left,
        y: position.top,
        width: sizing.width,
        height: sizing.height
      }}
      enableResizing={!!window.resizable}
      dragHandleClassName="rnd-handle"
      resizeHandleComponent={
        window.resizable
          ? {
              bottomRight: <Icon type={IconType.resize} color="white" size={16} />
            }
          : undefined
      }
      resizeHandleStyles={{
        bottomRight: {
          right: "8px",
          bottom: "8px"
        }
      }}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        opacity: loading ? 0 : 1,
        transition: "opacity 0.5s ease-in-out"
      }}
    >
      <WindowHeader window={window} closeWindow={windowCtx.closeWindow} />
      <ContentWrapper resizable={window.resizable}>{content}</ContentWrapper>
      {window.resizable && (
        <FooterBar>
          <FooterWrapper></FooterWrapper>
        </FooterBar>
      )}
    </Rnd>
  )
}
