import { useEffect } from "react"
import { RefObject, useCallback } from "react"
import { UseWebRTCProps } from "../webrtc"

export type UE4Video = {
  onTrack: UseWebRTCProps["onTrack"]
}

export type UseUE4VideoProps = {
  videoRef: RefObject<HTMLVideoElement>
  onVideoInitialized: () => void
}

export const useUE4Video = (props: UseUE4VideoProps): UE4Video => {
  const { videoRef, onVideoInitialized } = props

  const onTrack = useCallback<UseWebRTCProps["onTrack"]>((ev) => {
    const video = videoRef.current
    if (!video) return
    const stream = ev.streams[0]
    video.srcObject = stream
  }, [])

  useEffect(() => {
    videoRef.current?.addEventListener("loadedmetadata", onVideoInitialized)

    return () => {
      videoRef.current?.removeEventListener("loadedmetadata", onVideoInitialized)
    }
  }, [])

  return {
    onTrack
  }
}
