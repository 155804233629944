export type UseUEGeometryProps = {
  playerRef: React.RefObject<HTMLDivElement>
  videoRef: React.RefObject<HTMLVideoElement>
}

export type Coordinate = {
  x: number
  y: number
}

export type CoordinateWithRange = Coordinate & {
  inRange: boolean
}

export type CoordTranslationFunc<R extends Coordinate> = (x: number, y: number) => R | null

export interface UseUEGeometry {
  normalizeAndQuantizeUnsigned: CoordTranslationFunc<CoordinateWithRange>
  // unquantizeAndDenormalizeUnsigned: CoordTranslationFunc<Coordinate>
  normalizeAndQuantizeSigned: CoordTranslationFunc<Coordinate>
}

export const useUEGeometry = (props: UseUEGeometryProps): UseUEGeometry => {
  const { playerRef, videoRef } = props

  const getInfo = () => {
    const player = playerRef.current
    const video = videoRef.current

    const playerAspectRatio = (player?.clientHeight || 0) / (player?.clientWidth || 1)
    const videoAspectRatio = (video?.videoHeight || 0) / (video?.videoWidth || 1)

    const playerWidth = player?.clientWidth || 0
    const playerHeight = player?.clientHeight || 0

    const offsetLeft = player?.offsetLeft || 0
    const offsetTop = player?.offsetTop || 0

    return {
      player,
      video,
      playerAspectRatio,
      videoAspectRatio,
      playerWidth,
      playerHeight,
      offsetLeft,
      offsetTop
    }
  }

  const normalizeAndQuantizeUnsigned: CoordTranslationFunc<CoordinateWithRange> = (x: number, y: number) => {
    const { player, video, playerAspectRatio, videoAspectRatio, playerWidth, playerHeight } = getInfo()

    if (!player || !video) return null
    if (playerAspectRatio > videoAspectRatio) {
      const ratio = playerAspectRatio / videoAspectRatio
      const normalizedX = x / playerWidth
      const normalizedY = ratio * (y / playerHeight - 0.5) + 0.5
      if (normalizedX < 0.0 || normalizedX > 1.0 || normalizedY < 0.0 || normalizedY > 1.0) {
        return {
          inRange: false,
          x: 65535,
          y: 65535
        }
      } else {
        return {
          inRange: true,
          x: normalizedX * 65536,
          y: normalizedY * 65536
        }
      }
    } else {
      const ratio = videoAspectRatio / playerAspectRatio
      const normalizedX = ratio * (x / playerWidth - 0.5) + 0.5
      const normalizedY = y / playerHeight
      if (normalizedX < 0.0 || normalizedX > 1.0 || normalizedY < 0.0 || normalizedY > 1.0) {
        return {
          inRange: false,
          x: 65535,
          y: 65535
        }
      } else {
        return {
          inRange: true,
          x: normalizedX * 65536,
          y: normalizedY * 65536
        }
      }
    }
  }

  // const unquantizeAndDenormalizeUnsigned: CoordTranslationFunc<Coordinate> = (x: number, y: number) => {
  //   const { player, video, playerAspectRatio, videoAspectRatio, playerWidth, playerHeight } = getInfo()
  //   if (!player || !video) return null
  //   if (playerAspectRatio > videoAspectRatio) {
  //     const ratio = playerAspectRatio / videoAspectRatio
  //     const normalizedX = x / 65536
  //     const normalizedY = (y / 65536 - 0.5) / ratio + 0.5
  //     return {
  //       x: normalizedX * playerWidth,
  //       y: normalizedY * playerHeight
  //     }
  //   } else {
  //     const ratio = videoAspectRatio / playerAspectRatio
  //     const normalizedX = (x / 65536 - 0.5) / ratio + 0.5
  //     const normalizedY = y / 65536
  //     return {
  //       x: normalizedX * playerWidth,
  //       y: normalizedY * playerHeight
  //     }
  //   }
  // }

  const normalizeAndQuantizeSigned: CoordTranslationFunc<Coordinate> = (x: number, y: number) => {
    const { player, video, playerAspectRatio, videoAspectRatio, playerWidth, playerHeight } = getInfo()
    if (!player || !video) return null
    if (playerAspectRatio > videoAspectRatio) {
      const ratio = playerAspectRatio / videoAspectRatio
      const normalizedX = x / (0.5 * playerWidth)
      const normalizedY = (ratio * y) / (0.5 * playerHeight)
      return {
        x: normalizedX * 32767,
        y: normalizedY * 32767
      }
    } else {
      const ratio = videoAspectRatio / playerAspectRatio
      const normalizedX = (ratio * x) / (0.5 * playerWidth)
      const normalizedY = y / (0.5 * playerHeight)
      return {
        x: normalizedX * 32767,
        y: normalizedY * 32767
      }
    }
  }

  return {
    normalizeAndQuantizeUnsigned,
    // unquantizeAndDenormalizeUnsigned,
    normalizeAndQuantizeSigned
    // getInfo
    // offsetLeft: getInfo(),
    // offsetTop
  }
}
