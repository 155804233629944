import React, { ButtonHTMLAttributes } from "react"
import styled, { css } from "styled-components"
import { Icon, IconType } from "./Icon"

export type ButtonContainerProps = {
  backgroundColor: string
  foregroundColor: string
  selected?: boolean
  padding?: string
  disabled?: boolean
}

export const ButtonContainer = styled.button<ButtonContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  ${(props) => `padding: ${props.padding || "8px 12px"}`};

  border: 0;

  border-radius: 4px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);

  transition: all 0.18s ease-in-out;
  cursor: pointer;

  ${(props) => {
    return css`
      background-color: ${props.backgroundColor};
      color: ${props.foregroundColor};
      fill: ${props.foregroundColor};
      opacity: ${props.disabled ? 0.5 : 1};
    `
  }}
`

export type ButtonIntent = "primary" | "util" | "secondary"

export interface IButtonProps {
  label?: string
  icon?: IconType
  iconSize?: number
  iconTransform?: string
  intent: ButtonIntent
  selected?: boolean
  selectedColor?: string
  padding?: string
  strokeIcon?: boolean
}

export const ButtonIntents: {
  [K in ButtonIntent]: ButtonContainerProps
} = {
  primary: {
    backgroundColor: "rgba(10, 64, 80, 0.98)",
    foregroundColor: "rgba(255, 255, 255, 0.9)"
  },
  util: {
    backgroundColor: "transparent",
    foregroundColor: "rgba(255, 255, 255, 0.8)"
  },
  secondary: {
    backgroundColor: "#FF8137",
    foregroundColor: "white"
  }
}

export const Button = React.forwardRef<HTMLButtonElement, IButtonProps & ButtonHTMLAttributes<HTMLButtonElement>>(
  (props, ref) => {
    const { label, icon, iconSize, iconTransform, intent, padding, strokeIcon } = props

    const settings = ButtonIntents[intent]
    const { backgroundColor, foregroundColor } = settings

    return (
      <ButtonContainer
        {...props}
        padding={padding}
        ref={ref}
        selected={props.selected}
        backgroundColor={backgroundColor}
        foregroundColor={foregroundColor}
      >
        <span style={{ textAlign: "center", fontFamily: "inherit", fontWeight: 600 }}>{label && label}</span>
        {icon && (
          <Icon
            type={icon}
            size={label ? 16 : iconSize || 20}
            color={props.selected ? props.selectedColor || "royalblue" : foregroundColor}
            transform={iconTransform}
            stroke={strokeIcon}
          />
        )}
      </ButtonContainer>
    )
  }
)

Button.displayName = "Button"
