import { useCallback, useEffect, useState } from "react"
import { Axios } from "axios"
import { z } from "zod"
import Config from "../config"

const API = new Axios({
  responseType: "json",
  baseURL: Config.REACT_APP_CRM_URL,
  headers: {
    Authorization: `Token ${Config.REACT_APP_CRM_TOKEN}`
  }
})

export type UseCRMGetProps<T extends z.ZodTypeAny> = {
  path: string
  query?: Record<string, string>
  schema: T
}

export const useCRMGet = <T extends z.ZodTypeAny>(props: UseCRMGetProps<T>): { data: z.infer<T> | null } => {
  const [data, setData] = useState<T | null>(null)

  const fetch = useCallback(async () => {
    const response = await API.get(props.path, { params: props.query })
    const results = JSON.parse(response.data)
    const parsed = props.schema.parse(results)
    setData(parsed)
  }, [props.path])

  useEffect(() => {
    fetch()
  }, [])

  return {
    data
  }
}
