import React, { useContext, useState } from "react"
import { Slider, SliderProps } from "@mui/material"
import { styled as muiStyled } from "@mui/material/styles"
import styled from "styled-components"
import { FilterField, FilterFieldSet, FiltersBar } from "../FiltersBar"
import { SegmentControl } from "../SegmentControl"
import { Icon, IconType } from "../Icon"
import { NEW_WINDOW, ShowroomWindowContext } from "../../contexts/ShowroomWindowContext"
import {
  MAX_FLOOR,
  MAX_PRICE,
  MAX_SURFACE,
  MIN_FLOOR,
  MIN_PRICE,
  MIN_SURFACE,
  UnitFiltersContext
} from "../../contexts/UnitFiltersContext"
import { Button } from "../Button"
import { formatPrice } from "../../utils"

const Container = styled.div``
const ControlHandleContainer = styled.div`
  background-color: #f7f7fa;
  flex-grow: 0.6;
  display: flex;
  flex-direction: column;
  text-align: center;
  color: black;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  transition: all 0.3s;
`

const StyledSlider = muiStyled(Slider)({
  color: "rgba(6, 74, 95, 1)"
})

const ActionsWrapper = styled.div`
  padding: 12px;
  background-color: rgba(6, 74, 95, 1);
`
const ActionsContainer = styled.div`
  border-radius: 8px;
  overflow: hidden;
`
const ActionItemContainer = styled.div<{ foregroundColor: string }>`
  margin-top: 1px;
  background-color: #fff6f1;
  padding: 8px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  font-weight: 700;
  /* color: rgba(6, 74, 95, 1); */
  cursor: pointer;
  color: ${(props) => props.foregroundColor};
`
const COLOR_BY_INTENT = {
  primary: "#0D4657",
  destructive: "#FF3B30"
}
type ActionIntent = keyof typeof COLOR_BY_INTENT
type ActionItemProps = {
  title: string
  icon?: IconType
  intent: ActionIntent
  onClick: () => void
}
const ActionItem = (props: ActionItemProps): JSX.Element => {
  const color = COLOR_BY_INTENT[props.intent]
  return (
    <ActionItemContainer foregroundColor={color} onClick={props.onClick}>
      <span>{props.title}</span>
      {props.icon && <Icon type={props.icon} color={color} size={16} />}
    </ActionItemContainer>
  )
}

export type SliderHandleProps<T extends number | number[] | null> = {
  titleRenderer: (value: T) => string
}

const SliderHandle = (props: SliderHandleProps<number | number[] | null> & SliderProps): JSX.Element => {
  const { titleRenderer } = props
  const [isOpen, setIsOpen] = useState(false)

  const toggleOpen = () => {
    setIsOpen((prev) => !prev)
  }

  return (
    <ControlHandleContainer>
      <div style={{ padding: "6px 20px" }}>
        <div style={{ color: "rgba(6, 74, 95, 1)" }} onClick={toggleOpen}>
          {titleRenderer(props.value || null)}
        </div>
        {isOpen && (
          <StyledSlider
            value={props.value}
            min={props.min}
            max={props.max}
            step={props.step}
            marks={props.marks}
            onChange={props.onChange}
          />
        )}
      </div>
      {isOpen && (
        <Button
          intent="secondary"
          label="Fermer"
          style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
          onClick={toggleOpen}
        />
      )}
    </ControlHandleContainer>
  )
}

const MasterFilters = (): JSX.Element => {
  const windowCtx = useContext(ShowroomWindowContext)
  const filterCtx = useContext(UnitFiltersContext)
  // const { switchesValues, onSwitchChange } = useSwitchGroup({ keys: [SWITCH_KEY_APPARTMENTS, SWITCH_KEY_COMMERCIAL] })

  return (
    <Container>
      <FiltersBar hidesHeader>
        {/* <FilterFieldSet>
          <FilterField title="Appartement">
            <Toggle
              checked={switchesValues.get(SWITCH_KEY_APPARTMENTS) || false}
              onChange={onSwitchChange}
              id={SWITCH_KEY_APPARTMENTS}
            />
          </FilterField>
          <FilterField title="Commercial">
            <Toggle
              checked={switchesValues.get(SWITCH_KEY_COMMERCIAL) || false}
              onChange={onSwitchChange}
              id={SWITCH_KEY_COMMERCIAL}
            />
          </FilterField>
        </FilterFieldSet> */}
        <FilterFieldSet>
          <FilterField title="Etage" grid="2fr 5fr">
            <SliderHandle
              titleRenderer={(values) => {
                if (!values || typeof values === "number") {
                  return "Choisir son etage"
                }
                return `Entre le ${values[0]} et le ${values[1]}`
              }}
              value={filterCtx.filters.floorRange}
              min={MIN_FLOOR}
              max={MAX_FLOOR}
              step={1}
              marks
              onChange={(ev, values) => {
                if (typeof values === "number") return
                const [a, b] = values
                filterCtx.dispatchFilters({ type: "set_floor_range", floorRange: [a, b] })
              }}
            ></SliderHandle>
          </FilterField>
        </FilterFieldSet>
        <FilterFieldSet>
          <FilterField title="Chambres" grid="2fr 5fr">
            <SegmentControl<0 | 1 | 2 | 3>
              multi
              defaultSelected={new Set([0, 1, 2, 3])}
              selectedValues={filterCtx.filters.bedrooms}
              onChange={(value) => filterCtx.dispatchFilters({ type: "set_bedrooms", bedrooms: new Set(value) })}
              items={[
                { id: 0, title: "Studio" },
                { id: 1, title: "1" },
                { id: 2, title: "2" },
                { id: 3, title: "3" }
              ]}
            />
          </FilterField>
        </FilterFieldSet>
        <FilterFieldSet>
          <FilterField title="Superficie (pi2)" grid="2fr 5fr">
            <SliderHandle
              titleRenderer={(values) => {
                if (!values || values.constructor !== Array) {
                  return "Choisir la superficie"
                }
                return `Entre ${values[0]} et ${values[1]} pieds carré`
              }}
              value={filterCtx.filters.surfaceRange}
              min={MIN_SURFACE}
              max={MAX_SURFACE}
              step={10}
              marks
              onChange={(ev, values) => {
                if (typeof values === "number") return
                const [a, b] = values
                filterCtx.dispatchFilters({ type: "set_surface_range", surfaceRange: [a, b] })
              }}
            ></SliderHandle>
          </FilterField>
        </FilterFieldSet>
        <FilterFieldSet>
          <FilterField title="Prix" grid="2fr 5fr">
            <SliderHandle
              titleRenderer={(values) => {
                if (!values || values.constructor !== Array) {
                  return "Choisir son prix"
                }
                return `Entre ${formatPrice(values[0])} et ${formatPrice(values[1])}`
              }}
              value={filterCtx.filters.priceRange}
              min={MIN_PRICE}
              max={MAX_PRICE}
              step={1000000}
              marks
              onChange={(ev, values) => {
                if (typeof values === "number") return
                const [a, b] = values
                filterCtx.dispatchFilters({ type: "set_price_range", priceRange: [a, b] })
              }}
            ></SliderHandle>
          </FilterField>
        </FilterFieldSet>
        <FilterFieldSet>
          <FilterField title="Orientation" grid="2fr 5fr">
            <SegmentControl<"nord" | "est" | "sud" | "ouest">
              defaultSelected={new Set(["nord"])}
              selectedValues={filterCtx.filters.orientation}
              multi
              onChange={(value) => filterCtx.dispatchFilters({ type: "set_orientation", orientation: new Set(value) })}
              items={[
                { id: "nord", title: "Nord" },
                { id: "est", title: "Est" },
                { id: "sud", title: "Sud" },
                { id: "ouest", title: "Ouest" }
              ]}
            />
          </FilterField>
        </FilterFieldSet>
      </FiltersBar>
      <ActionsWrapper>
        <ActionsContainer>
          <ActionItem
            intent="primary"
            title="Voir la liste des unites"
            icon={IconType.building}
            onClick={() => windowCtx.addWindow(NEW_WINDOW.units_list)}
          />
          <ActionItem
            intent="destructive"
            title="Effacer les filtres"
            icon={IconType.trash}
            onClick={() => filterCtx.dispatchFilters({ type: "reset" })}
          ></ActionItem>
        </ActionsContainer>
      </ActionsWrapper>
    </Container>
  )
}

export default MasterFilters
