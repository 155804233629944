import { useCallback, useEffect, useRef } from "react"
import EventEmitter from "events"

export interface Signalling {
  onOfferCreated: (offer: RTCSessionDescriptionInit) => void
  onIceCandidate: (iceEvent: RTCPeerConnectionIceEvent) => void
  emitter: EventEmitter
}

export interface UseSignallingProps {
  endpoint?: string
  token?: string | null
}

export const useSignalling = (props: UseSignallingProps): Signalling => {
  const { endpoint, token } = props

  const wsRef = useRef<WebSocket | null>(null)
  const emitterRef = useRef(new EventEmitter())

  const initialize = useCallback(() => {
    if (!endpoint || !token) return

    const ws = new WebSocket(`${endpoint}/?token=${token}`)
    ws.onopen = () => {
      console.log("WS connection open")
    }
    ws.onmessage = handleMessage
    wsRef.current = ws
  }, [endpoint, token])

  const handleMessage = useCallback((ev: MessageEvent<string>) => {
    console.log("handle ws message:", ev.data)

    try {
      const data = JSON.parse(ev.data)
      emitterRef.current.emit(data.type, data)
    } catch (e) {
      console.error("Failed to handle message", e)
    }
  }, [])

  const sendMessage: (msg: RTCSessionDescriptionInit | RTCPeerConnectionIceEvent) => void = (msg) => {
    wsRef.current?.send(JSON.stringify(msg))
  }

  const onOfferCreated: (offer: RTCSessionDescriptionInit) => void = (offer) => {
    sendMessage(offer)
  }

  const onIceCandidate: (iceEvent: RTCPeerConnectionIceEvent) => void = (iceEvent) => {
    sendMessage(iceEvent)
  }

  useEffect(() => {
    initialize()
  }, [initialize])

  return {
    onOfferCreated,
    onIceCandidate,
    emitter: emitterRef.current
  }
}
