import dotenv from "dotenv"
import { z } from "zod"

dotenv.config()
console.log(process.env)

const BoolSchema = z.enum(["true", "false"]).transform((val) => JSON.parse(val))

const ConfigSchema = z.object({
  REACT_APP_CRM_URL: z.string().url(),
  REACT_APP_CRM_TOKEN: z.string(),
  REACT_APP_BACKEND_URL: z.string().url(),
  REACT_APP_DEV_MODE: BoolSchema.default("false")
})

const Config = ConfigSchema.parse(process.env)

export default Config
