import React, { useContext, useMemo, useState } from "react"
import styled from "styled-components"
import { CRMContext, GalleryItemType, ICRMContext, UnitType } from "../../contexts/CRMContext"
import { FavoritesContext, IFavoritesContext } from "../../contexts/FavoritesContext"
import { ShowroomSessionContext } from "../../contexts/ShowroomSessionContext"
import { FavoriteIdentifier } from "../../hooks/useFavoritesReducer"
import { Button } from "../Button"
import { GalleryCard } from "../GalleryCard"

import { UnitCard } from "../UnitCard"
import { WindowedModal } from "../WindowedModal"

const FavoritesContainer = styled.div`
  /* background-color: rgba(255, 246, 241, 0.85); */
  height: 100%;
`

const CardListContainer = styled.div`
  padding: 16px 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding-bottom: 50px;
  align-items: stretch;
`

export type FavoriteCardProps = {
  favorite: {
    name: string
    description: string
  }
}

export type FavoriteObject =
  | { type: "unit"; unit: UnitType; identifier: FavoriteIdentifier }
  | { type: "gallery"; item: GalleryItemType; identifier: FavoriteIdentifier }

const useFavoriteObjects = (props: { maps: ICRMContext["maps"]; favorites: IFavoritesContext["favorites"] }) => {
  const favoriteObjects = useMemo(() => {
    const ids = Array.from(props.favorites)
    const objs = ids.reduce((acc, identifier) => {
      const [type, id] = identifier.split(":")
      switch (type) {
        case "unit": {
          const unit = props.maps.units.get(id)
          if (unit) acc.push({ type: "unit", unit, identifier })
          break
        }

        case "gallery": {
          const item = props.maps.gallery.get(id)
          if (item) acc.push({ type: "gallery", item, identifier })
          break
        }
        default:
          break
      }
      return acc
    }, Array<FavoriteObject>())

    return objs.sort((a, b) => {
      if (a.type === "unit" && b.type === "gallery") return -1
      if (b.type === "unit" && a.type === "gallery") return 1
      return 0
    })
  }, [props.favorites, props.maps])

  return favoriteObjects
}

const CardList = (): JSX.Element => {
  const { sendFavoritesEmail } = useContext(ShowroomSessionContext)
  const { favorites, toggleFavorite } = useContext(FavoritesContext)
  const { maps } = useContext(CRMContext)

  const favoriteObjects = useFavoriteObjects({ favorites, maps })

  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <>
      <CardListContainer>
        {!favoriteObjects.length && <div>Vous n&lsquo;avez pas ajouté de favoris.</div>}
        {favoriteObjects.map((fave) => {
          const { type, identifier } = fave
          switch (type) {
            case "unit":
              return (
                <UnitCard
                  key={fave.unit.id}
                  collapsible={false}
                  unit={fave.unit}
                  actions={new Set(["blueprint", "favorite_delete"])}
                />
              )
            case "gallery":
              return (
                <GalleryCard
                  key={fave.item.id}
                  imageUrl={fave.item.meta.download_url}
                  title={fave.item.title}
                  footerText={fave.item.meta.tags.filter((t) => t !== "app_gallery").join("/")}
                  // onClick={() => handleClick(fave.item)}
                  selected={true}
                  onHeartClick={() => toggleFavorite(identifier)}
                />
              )
          }
        })}
      </CardListContainer>
      {!!favoriteObjects.length && (
        <>
          <Button
            intent="secondary"
            label="Envoyer les favoris maintenant"
            style={{ position: "absolute", bottom: 0, width: "100%", borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
            onClick={() => setIsModalOpen(true)}
          />
          <WindowedModal
            open={isModalOpen}
            title="Envoyer les favoris"
            actions={[
              {
                title: "Annuler",
                intent: "secondary",
                onClick: () => setIsModalOpen(false)
              },
              {
                title: "Envoyer",
                intent: "secondary",
                onClick: () => {
                  sendFavoritesEmail()
                  setIsModalOpen(false)
                }
              }
            ]}
          >
            <p>Les favoris seront envoyés a l&lsquo;addresse courriel spécifiée au debut de la session.</p>
          </WindowedModal>
        </>
      )}
    </>
  )
}

const Favorites = (): JSX.Element => {
  return (
    <FavoritesContainer>
      <CardList />
    </FavoritesContainer>
  )
}

export default Favorites
