import React, { useContext, useState } from "react"
import styled, { css } from "styled-components"
import { UnitType } from "../contexts/CRMContext"
import { FavoritesContext } from "../contexts/FavoritesContext"
import { ShowroomLevel, ShowroomLevelContext } from "../contexts/ShowroomLevelContext"
import { NEW_WINDOW, ShowroomWindowContext } from "../contexts/ShowroomWindowContext"
import { FavoriteIdentifier } from "../hooks/useFavoritesReducer"
import { formatPrice } from "../utils"
import { Button } from "./Button"
import { Icon, IconType } from "./Icon"

const CardContainer = styled.div`
  border-radius: 14px;
  overflow: hidden;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  flex-grow: 1;
`

const CardHeader = styled.div`
  background-color: #0d4657;
  cursor: pointer;
  color: white;
  padding: 16px;
  display: flex;
  align-items: center;
`

const HeaderColumn = styled.div<{ flexGrow?: number }>`
  ${(props) =>
    props.flexGrow &&
    css`
      flex-grow: ${props.flexGrow};
    `}
`

const CardContent = styled.div`
  background-color: #fff6f1;
`

const UnitAttributesContainer = styled.div`
  padding: 12px 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`
const UnitAttributeLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #1c677c;
`

const UnitPrice = styled.div`
  font-size: 20px;
  font-weight: 600;
  padding: 0px 20px;
  color: #1c677c;
`

const FavoriteActions = styled.div`
  display: flex;
  padding: 16px;
  gap: 8px;
`

export type UnitAttributeProps = {
  icon: IconType
  text: string
}

const UnitAttribute = (props: UnitAttributeProps): JSX.Element => {
  return (
    <UnitAttributeLine>
      <Icon type={props.icon} color="#FF8137" size={20} />
      <span style={{ marginLeft: "16px" }}>{props.text}</span>
    </UnitAttributeLine>
  )
}

export type UnitAttributesProps = {
  unit: UnitType
}

function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

const UnitAttributes = (props: UnitAttributesProps): JSX.Element => {
  const { bedrooms, orientation } = props.unit
  return (
    <UnitAttributesContainer>
      <UnitAttribute icon={IconType.bed} text={`${bedrooms} Chambres`} />
      {/* <UnitAttribute icon={IconType.shower} text={`${bathrooms} salles de bain`} /> */}
      <UnitAttribute icon={IconType.compass} text={`${orientation.map((o) => capitalizeFirstLetter(o)).join(", ")}`} />
    </UnitAttributesContainer>
  )
}

const useFloorDescription = (floor: number) => {
  if (floor === 1) return "1er"
  return `${floor}eme`
}
const useUnitDescription = (unit: UnitType) => {
  const { floor, surface, phase } = unit
  const floorDesc = useFloorDescription(floor)
  return `${floorDesc} Étage / ${surface} pi2 / phase ${phase}`
}
const usePriceDescription = (price: number) => {
  return formatPrice(price)
}

export type UnitCardAction = "blueprint" | "visit" | "favorite_toggle" | "favorite_delete"
export type UnitCardProps = {
  unit: UnitType
  actions: Set<UnitCardAction>
  collapsible?: boolean
  level?: ShowroomLevel
  defaultOpen?: boolean
}

export const UnitCard = (props: UnitCardProps): JSX.Element => {
  const { toggleFavorite, favorites } = useContext(FavoritesContext)
  const windowCtx = useContext(ShowroomWindowContext)
  const levelCtx = useContext(ShowroomLevelContext)
  const [isOpen, setIsOpen] = useState(!props.collapsible || props.defaultOpen)

  const { id, price, plan_image_url } = props.unit
  const description = useUnitDescription(props.unit)
  const pricedesc = usePriceDescription(price)
  const favoriteIdentifier: FavoriteIdentifier = `unit:${props.unit.id}`

  const handleVisitClick = () => {
    const currentLevel = levelCtx.selectedLevel
    if (!currentLevel || !props.level?.id) return
    if (currentLevel.id === props.level?.id) {
      return
    }

    levelCtx.changeLevel(props.level.id)
  }

  const handleDeleteFavorite = () => {
    toggleFavorite(`unit:${props.unit.id}`)
  }

  const handleBlueprint = () => {
    if (plan_image_url) {
      windowCtx.addWindow({ ...NEW_WINDOW.plan_viewer, title: `Unit ${id}` }, props.unit)
    }
  }

  return (
    <CardContainer>
      <CardHeader onClick={() => setIsOpen(!isOpen)}>
        <HeaderColumn flexGrow={1}>
          <h1>{id.toString()}</h1>
          <h4 style={{ fontWeight: 500 }}>{description}</h4>
        </HeaderColumn>
        {props.collapsible && (
          <HeaderColumn>
            <Button
              intent="util"
              icon={IconType.arrow_right_circle}
              iconSize={24}
              strokeIcon
              iconTransform={isOpen ? "rotate(90)" : undefined}
            >
              Open/Close
            </Button>
          </HeaderColumn>
        )}
      </CardHeader>
      {isOpen && (
        <CardContent>
          <UnitAttributes unit={props.unit} />
          <UnitPrice>{pricedesc}</UnitPrice>
          <FavoriteActions>
            {props.actions.has("blueprint") && (
              <Button
                intent="primary"
                label="Plan"
                style={{ flexGrow: 1 }}
                padding="12px"
                disabled={!plan_image_url}
                onClick={handleBlueprint}
              />
            )}
            {props.actions.has("visit") && (
              <Button intent="primary" label="Visite Virtuelle" onClick={() => handleVisitClick()} />
            )}
            {props.actions.has("favorite_delete") && (
              <Button intent="primary" icon={IconType.trash} padding="12px" onClick={handleDeleteFavorite} />
            )}
            {props.actions.has("favorite_toggle") && (
              <Button
                intent="primary"
                icon={IconType.heart}
                color="white"
                selectedColor="#FF3B30"
                padding="12px"
                selected={favorites.has(favoriteIdentifier)}
                onClick={() => toggleFavorite(favoriteIdentifier)}
              />
            )}
          </FavoriteActions>
        </CardContent>
      )}
    </CardContainer>
  )
}
