import React, { HTMLProps } from "react"
import styled from "styled-components"
import { InputLabel } from "./InputLabel"

const InputContainer = styled.div<{ inverted?: boolean; disabled?: boolean }>`
  padding: 8px;

  border-radius: 6px;

  display: flex;
  flex-direction: column;

  background-color: rgba(0, 0, 0, 0.5);
  opacity: ${(props) => (props.disabled ? 0.6 : 1)};

  color: ${(props) => (props.inverted ? "black" : "white")};
  background-color: ${(props) => (props.inverted ? "rgba(0, 0, 0, 0.1)" : "rgba(0, 0, 0, 0.5)")};
`

type InputProps = HTMLProps<HTMLInputElement> & {
  label: string
  inverted?: boolean
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const { inverted, ...other } = props
  return (
    <div>
      <InputLabel inverted={inverted} disabled={other.disabled}>
        {props.label}
      </InputLabel>
      <InputContainer inverted={inverted} disabled={other.disabled}>
        <input {...other} ref={ref} autoComplete="off" autoCorrect="off"></input>
      </InputContainer>
    </div>
  )
})

Input.displayName = "Input"
