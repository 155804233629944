import React, { PropsWithChildren, useContext, useEffect, useMemo, useState } from "react"
import styled from "styled-components"
import { Button } from "../components/Button"
import { DialogBox } from "../components/Dialog"
import { Input } from "../components/Input"
import { Select } from "../components/Select"
import { TextArea } from "../components/TextArea"
import { AuthContext } from "../contexts/AuthContext"
import { CRMContext } from "../contexts/CRMContext"
import { PixelStreamingContext } from "../contexts/PixelStreamingContext"
import { SalesPerson, ShowroomSessionContext, StartSessionParamsSchema } from "../contexts/ShowroomSessionContext"

const Wrapper = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("https://lesloges-public-s3-assets-prod-1.s3.amazonaws.com/media/images/availability-img-home.original.png");
  background-size: cover;
  overflow: hidden;
  z-index: 10000;
`

const PortalContainer = styled.dialog`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: scroll;
`
const Error = styled.div`
  margin: 0px 20px 12px 20px;
  background-color: #e4cece;
  color: #8a1515;
  padding: 8px 12px;
  border-radius: 8px;
`

const Portal = (props: PropsWithChildren<unknown>): JSX.Element => {
  return <PortalContainer>{props.children}</PortalContainer>
}

export const Form = styled.form`
  padding: 20px;
`

export const FieldSet = styled.fieldset`
  border: none;
  padding: 10px 0px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 12px;
  font-size: 14px;
`

export const NewSessionPage = (): JSX.Element => {
  const crmCtx = useContext(CRMContext)
  const authCtx = useContext(AuthContext)
  const sessionContext = useContext(ShowroomSessionContext)
  const pixelCtx = useContext(PixelStreamingContext)

  const [showForm, setShowForm] = useState(true)

  const [error, setError] = useState<string | null>(null)

  const [email, setEmail] = useState("")
  const [salesperson, setSalesPerson] = useState<SalesPerson | null>(null)
  const [telephone, setTelephone] = useState("")
  const [note, setNote] = useState("")
  const [first_name, setFirstName] = useState("")
  const [last_name, setLastName] = useState("")

  const fieldsValid = useMemo(() => {
    const result = StartSessionParamsSchema.safeParse({
      lead: {
        email,
        first_name,
        last_name,
        telephone
      },
      salesperson,
      note
    })

    return result.success
  }, [email, first_name, last_name, telephone, salesperson, note])

  const startSession = async () => {
    if (!showForm) {
      await sessionContext.startSession(null)
      return
    }

    if (!salesperson) return
    try {
      await sessionContext.startSession({
        lead: {
          email,
          first_name,
          last_name,
          telephone
        },
        salesperson,
        note
      })
      setError(null)
    } catch (e) {
      console.log({ e })
      setError("Les informations sont invalides.")
    }
  }

  useEffect(() => {
    if (pixelCtx.webrtcReady && sessionContext.currentSession) {
      pixelCtx.connect()
    }
  }, [pixelCtx.webrtcReady, sessionContext.currentSession])

  const buttonTitle = pixelCtx.webrtcReady ? (sessionContext.currentSession ? "Chargement..." : "Commencer") : "En attente"

  return (
    <Wrapper>
      <Portal>
        <DialogBox
          title="VIVENDA"
          message="Soyez aux premières loges."
          actions={[
            {
              title: buttonTitle,
              onClick: () => startSession(),
              disabled: () => {
                if (!pixelCtx.webrtcReady) return true
                if (showForm) {
                  return !fieldsValid || !!sessionContext.currentSession
                }
                return false
              }
            }
          ]}
        >
          {showForm && (
            <>
              <Form>
                <FieldSet>
                  <Select
                    label="Représentant"
                    name="sales"
                    isMulti={false}
                    value={salesperson}
                    placeholder="Sélectionner un représentant..."
                    options={crmCtx.data.salespeople || []}
                    getOptionLabel={(opt) => [opt.first_name, opt.last_name].join(" ")}
                    getOptionValue={(opt) => opt.id.toString()}
                    onChange={(newValue) => {
                      setSalesPerson(newValue)
                    }}
                  />
                </FieldSet>
                <FieldSet>
                  <Input
                    label="Prénom"
                    autoCapitalize="words"
                    name="first_name"
                    placeholder="John"
                    value={first_name}
                    onChange={(ev) => setFirstName(ev.currentTarget.value)}
                    disabled={!salesperson}
                  />
                  <Input
                    label="Nom"
                    name="last_name"
                    placeholder="Doe"
                    value={last_name}
                    onChange={(ev) => setLastName(ev.currentTarget.value)}
                    disabled={!salesperson}
                  />
                  <Input
                    label="Courriel"
                    name="customer_email"
                    type="email"
                    placeholder="example@example.com"
                    value={email}
                    onChange={(ev) => setEmail(ev.currentTarget.value)}
                    disabled={!salesperson}
                  />
                  <Input
                    label="Téléphone"
                    name="phone"
                    type="phone"
                    placeholder="123 456 7890"
                    value={telephone}
                    onChange={(ev) => setTelephone(ev.currentTarget.value)}
                    disabled={!salesperson}
                  />
                  <TextArea
                    label="Note"
                    name="note"
                    value={note}
                    onChange={(ev) => setNote(ev.currentTarget.value)}
                    disabled={!salesperson}
                  />
                </FieldSet>
              </Form>
              {!!error && <Error>{error}</Error>}
            </>
          )}
        </DialogBox>
        <div
          style={{
            display: "flex",
            gap: "4px"
          }}
        >
          <Button intent="primary" label="Se déconnecter" onClick={() => authCtx.logout()} />
          <Button
            intent="primary"
            label={showForm ? "Cacher le formulaire" : "Afficher le formulaire"}
            onClick={() => {
              setShowForm(!showForm)
            }}
          />
        </div>
      </Portal>
    </Wrapper>
  )
}
