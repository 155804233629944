import React from "react"
import ReactSelect from "react-select"
import { StateManagerProps } from "react-select/dist/declarations/src/useStateManager"
import { InputLabel } from "./InputLabel"

export type SelectProps<Option, IsMulti extends boolean> = StateManagerProps<Option, IsMulti> & {
  label: string
}

export function Select<Option, IsMulti extends boolean>(props: SelectProps<Option, IsMulti>): JSX.Element {
  return (
    <div>
      <InputLabel>{props.label}</InputLabel>
      <ReactSelect
        isClearable={true}
        styles={{
          control: (provided) => {
            return {
              ...provided,
              outline: "none",
              padding: "0px",
              border: "none",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              cursor: "pointer"
            }
          },
          menu: (provided) => {
            return {
              ...provided,
              outline: "none",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              backdropFilter: "blur(8px)"
            }
          },
          option: (provided, state) => {
            return {
              ...provided,
              outline: "none",
              color: "white",
              border: "none",
              cursor: "pointer",
              backgroundColor: state.isFocused ? "rgba(255, 255, 255, 0.1)" : "transparent"
              // backdropFilter: "blur(12px)"
            }
          },
          singleValue: (provided) => {
            return {
              outline: "none",
              ...provided,
              color: "white"
            }
          },
          indicatorSeparator: (provided) => {
            return {
              ...provided,
              backgroundColor: "rgba(255, 255, 255, 0.2)"
            }
          }
        }}
        {...props}
      />
    </div>
  )
}
