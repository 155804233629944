import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
};

export type CreateUserInput = {
  email: Scalars['String'];
  master_key: Scalars['String'];
  password: Scalars['String'];
};

export type GetPixelTokenInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  server: Scalars['String'];
};

export type Lead = {
  __typename?: 'Lead';
  email: Scalars['String'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  telephone?: Maybe<Scalars['String']>;
};

export type LeadInput = {
  email: Scalars['String'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  telephone?: InputMaybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createUser: User;
  getPixelToken: Scalars['String'];
  recordSessionEnd: Session;
  recordSessionStart: Session;
  sendFavorites: Scalars['Boolean'];
  updateSession: Session;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationGetPixelTokenArgs = {
  input: GetPixelTokenInput;
};


export type MutationRecordSessionEndArgs = {
  input: RecordSessionInput;
};


export type MutationRecordSessionStartArgs = {
  input: RecordSessionInput;
};


export type MutationSendFavoritesArgs = {
  input: SendFavoritesInput;
};


export type MutationUpdateSessionArgs = {
  input: RecordSessionInput;
};

export type Query = {
  __typename?: 'Query';
  me?: Maybe<Scalars['String']>;
};

export type RecordSessionInput = {
  favorites: Array<Scalars['String']>;
  lead: LeadInput;
  lead_session_id: Scalars['String'];
  note: Scalars['String'];
  salesperson: SalespersonInput;
  send_email: Scalars['Boolean'];
};

export type Salesperson = {
  __typename?: 'Salesperson';
  email: Scalars['String'];
  first_name: Scalars['String'];
  id: Scalars['Int'];
  last_name: Scalars['String'];
  telephone: Scalars['String'];
};

export type SalespersonInput = {
  email: Scalars['String'];
  first_name: Scalars['String'];
  id: Scalars['Int'];
  last_name: Scalars['String'];
  telephone: Scalars['String'];
};

export type SendFavoritesInput = {
  lead_session_id: Scalars['String'];
};

export type Session = {
  __typename?: 'Session';
  ended_at: Scalars['Date'];
  favorites: Array<Scalars['String']>;
  lead: Lead;
  lead_session_id: Scalars['String'];
  salesperson: Salesperson;
  started_at: Scalars['Date'];
};

export type User = {
  __typename?: 'User';
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
};

export type GetPixelTokenMutationVariables = Exact<{
  input: GetPixelTokenInput;
}>;


export type GetPixelTokenMutation = { __typename?: 'Mutation', getPixelToken: string };

export type RecordSessionEndMutationVariables = Exact<{
  input: RecordSessionInput;
}>;


export type RecordSessionEndMutation = { __typename?: 'Mutation', recordSessionEnd: { __typename?: 'Session', lead_session_id: string } };

export type RecordSessionStartMutationVariables = Exact<{
  input: RecordSessionInput;
}>;


export type RecordSessionStartMutation = { __typename?: 'Mutation', recordSessionStart: { __typename?: 'Session', lead_session_id: string } };

export type SendFavoritesMutationVariables = Exact<{
  input: SendFavoritesInput;
}>;


export type SendFavoritesMutation = { __typename?: 'Mutation', sendFavorites: boolean };

export type UpdateSessionMutationVariables = Exact<{
  input: RecordSessionInput;
}>;


export type UpdateSessionMutation = { __typename?: 'Mutation', updateSession: { __typename?: 'Session', lead_session_id: string } };


export const GetPixelTokenDocument = gql`
    mutation GetPixelToken($input: GetPixelTokenInput!) {
  getPixelToken(input: $input)
}
    `;
export type GetPixelTokenMutationFn = Apollo.MutationFunction<GetPixelTokenMutation, GetPixelTokenMutationVariables>;

/**
 * __useGetPixelTokenMutation__
 *
 * To run a mutation, you first call `useGetPixelTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetPixelTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getPixelTokenMutation, { data, loading, error }] = useGetPixelTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPixelTokenMutation(baseOptions?: Apollo.MutationHookOptions<GetPixelTokenMutation, GetPixelTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetPixelTokenMutation, GetPixelTokenMutationVariables>(GetPixelTokenDocument, options);
      }
export type GetPixelTokenMutationHookResult = ReturnType<typeof useGetPixelTokenMutation>;
export type GetPixelTokenMutationResult = Apollo.MutationResult<GetPixelTokenMutation>;
export type GetPixelTokenMutationOptions = Apollo.BaseMutationOptions<GetPixelTokenMutation, GetPixelTokenMutationVariables>;
export const RecordSessionEndDocument = gql`
    mutation RecordSessionEnd($input: RecordSessionInput!) {
  recordSessionEnd(input: $input) {
    lead_session_id
  }
}
    `;
export type RecordSessionEndMutationFn = Apollo.MutationFunction<RecordSessionEndMutation, RecordSessionEndMutationVariables>;

/**
 * __useRecordSessionEndMutation__
 *
 * To run a mutation, you first call `useRecordSessionEndMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecordSessionEndMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recordSessionEndMutation, { data, loading, error }] = useRecordSessionEndMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRecordSessionEndMutation(baseOptions?: Apollo.MutationHookOptions<RecordSessionEndMutation, RecordSessionEndMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RecordSessionEndMutation, RecordSessionEndMutationVariables>(RecordSessionEndDocument, options);
      }
export type RecordSessionEndMutationHookResult = ReturnType<typeof useRecordSessionEndMutation>;
export type RecordSessionEndMutationResult = Apollo.MutationResult<RecordSessionEndMutation>;
export type RecordSessionEndMutationOptions = Apollo.BaseMutationOptions<RecordSessionEndMutation, RecordSessionEndMutationVariables>;
export const RecordSessionStartDocument = gql`
    mutation RecordSessionStart($input: RecordSessionInput!) {
  recordSessionStart(input: $input) {
    lead_session_id
  }
}
    `;
export type RecordSessionStartMutationFn = Apollo.MutationFunction<RecordSessionStartMutation, RecordSessionStartMutationVariables>;

/**
 * __useRecordSessionStartMutation__
 *
 * To run a mutation, you first call `useRecordSessionStartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecordSessionStartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recordSessionStartMutation, { data, loading, error }] = useRecordSessionStartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRecordSessionStartMutation(baseOptions?: Apollo.MutationHookOptions<RecordSessionStartMutation, RecordSessionStartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RecordSessionStartMutation, RecordSessionStartMutationVariables>(RecordSessionStartDocument, options);
      }
export type RecordSessionStartMutationHookResult = ReturnType<typeof useRecordSessionStartMutation>;
export type RecordSessionStartMutationResult = Apollo.MutationResult<RecordSessionStartMutation>;
export type RecordSessionStartMutationOptions = Apollo.BaseMutationOptions<RecordSessionStartMutation, RecordSessionStartMutationVariables>;
export const SendFavoritesDocument = gql`
    mutation SendFavorites($input: SendFavoritesInput!) {
  sendFavorites(input: $input)
}
    `;
export type SendFavoritesMutationFn = Apollo.MutationFunction<SendFavoritesMutation, SendFavoritesMutationVariables>;

/**
 * __useSendFavoritesMutation__
 *
 * To run a mutation, you first call `useSendFavoritesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendFavoritesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendFavoritesMutation, { data, loading, error }] = useSendFavoritesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendFavoritesMutation(baseOptions?: Apollo.MutationHookOptions<SendFavoritesMutation, SendFavoritesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendFavoritesMutation, SendFavoritesMutationVariables>(SendFavoritesDocument, options);
      }
export type SendFavoritesMutationHookResult = ReturnType<typeof useSendFavoritesMutation>;
export type SendFavoritesMutationResult = Apollo.MutationResult<SendFavoritesMutation>;
export type SendFavoritesMutationOptions = Apollo.BaseMutationOptions<SendFavoritesMutation, SendFavoritesMutationVariables>;
export const UpdateSessionDocument = gql`
    mutation UpdateSession($input: RecordSessionInput!) {
  updateSession(input: $input) {
    lead_session_id
  }
}
    `;
export type UpdateSessionMutationFn = Apollo.MutationFunction<UpdateSessionMutation, UpdateSessionMutationVariables>;

/**
 * __useUpdateSessionMutation__
 *
 * To run a mutation, you first call `useUpdateSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSessionMutation, { data, loading, error }] = useUpdateSessionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSessionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSessionMutation, UpdateSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSessionMutation, UpdateSessionMutationVariables>(UpdateSessionDocument, options);
      }
export type UpdateSessionMutationHookResult = ReturnType<typeof useUpdateSessionMutation>;
export type UpdateSessionMutationResult = Apollo.MutationResult<UpdateSessionMutation>;
export type UpdateSessionMutationOptions = Apollo.BaseMutationOptions<UpdateSessionMutation, UpdateSessionMutationVariables>;