import React, { PropsWithChildren } from "react"
import ReactSwitch, { ReactSwitchProps } from "react-switch"
import styled, { css } from "styled-components"

const BarContainer = styled.div<{ hidesHeader?: boolean }>`
  background-color: rgba(6, 74, 95, 1);

  position: sticky;
  top: 0;
  font: inherit;
  font-size: 16px;

  ${(props) =>
    !props.hidesHeader &&
    css`
      border-top: 1px solid rgba(255, 255, 255, 0.2);
    `}
`

const Header = styled.div`
  font: inherit;
  font-size: 20px;
  color: rgba(255, 255, 255, 0.9);
  padding: 8px 12px 8px 12px;
`
export const FilterFieldSet = styled.div<{ grid?: string }>`
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  padding: 8px 12px 8px 12px;
  color: white;
  gap: 20px;

  ${(props) =>
    props.grid
      ? css`
          display: grid;
          grid-template-columns: ${props.grid};
          grid-gap: 12px;
        `
      : css`
          display: flex;
        `}
`

const FilterFieldContainer = styled.div<{ grid?: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;

  ${(props) =>
    props.grid &&
    css`
      display: grid;
      grid-template-columns: ${props.grid};
    `}
`
export const FilterField = (props: PropsWithChildren<{ title: string; grid?: string }>): JSX.Element => {
  return (
    <FilterFieldContainer grid={props.grid}>
      <div style={{ marginRight: "8px" }}>{props.title}</div>
      {props.children}
    </FilterFieldContainer>
  )
}

export const Toggle = (props: ReactSwitchProps): JSX.Element => {
  return (
    <div
      style={{
        marginTop: "4px",
        marginBottom: "-4px"
      }}
    >
      <ReactSwitch
        {...props}
        onColor={props.checked ? "#FF8137" : "#E0E0E0"}
        offColor={props.checked ? "#FF8137" : "#E0E0E0"}
        checkedIcon={false}
        uncheckedIcon={false}
      ></ReactSwitch>
    </div>
  )
}

export const FiltersBar = (props: PropsWithChildren<{ hidesHeader?: boolean }>): JSX.Element => {
  return (
    <BarContainer hidesHeader={props.hidesHeader}>
      {!props.hidesHeader && <Header>Filtres</Header>}
      {props.children}
    </BarContainer>
  )
}
