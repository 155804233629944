import { RefObject } from "react"
import { useUEGeometry, UseUEGeometryProps } from "./geometry"
import { UEEmitMessageCodes, UEEmitMessage } from "./messageCodes"

export type UEDescriptor = Record<string, unknown>

export const getMessageCode = (type: UEEmitMessage): number => {
  return UEEmitMessageCodes[type]
}

export type UseUE4CommandsProps = {
  dataChannelRef: RefObject<RTCDataChannel | null>
  playerRef: UseUEGeometryProps["playerRef"]
  videoRef: UseUEGeometryProps["videoRef"]
}
export type SendInputData = (data: ArrayBuffer) => void
export type EmitCode = (type: UEEmitMessage) => void
export type RequestInitialSettings = () => void
export type RequestQualityControl = () => void
export type EmitDescriptor = (type: UEEmitMessage, descriptor: UEDescriptor | null) => void
export type EmitUECommand = (descriptor: UEDescriptor) => void
export type EmitUIInteraction = (descriptor: UEDescriptor) => void
export type EmitMouseMove = (x: number, y: number, deltaX: number, deltaY: number) => void
export type EmitMouseDown = (button: number, x: number, y: number) => void
export type EmitMouseUp = (button: number, x: number, y: number) => void
export type EmitMouseEnter = () => void
export type EmitMouseLeave = () => void
export type EmitTouchData = (
  type:
    | typeof UEEmitMessageCodes["TouchStart"]
    | typeof UEEmitMessageCodes["TouchMove"]
    | typeof UEEmitMessageCodes["TouchEnd"],
  touches: TouchList,
  fingerIds: { [s: string]: number }
) => void

export interface UECommands {
  requestInitialSettings: RequestInitialSettings
  requestQualityControl: RequestQualityControl
  emitUECommand: EmitUECommand
  emitUIInteraction: EmitUIInteraction
  emitMouseMove: EmitMouseMove
  emitMouseDown: EmitMouseDown
  emitMouseUp: EmitMouseUp
  emitMouseEnter: EmitMouseEnter
  emitMouseLeave: EmitMouseLeave
  emitTouchData: EmitTouchData
}

export const useUECommands = (props: UseUE4CommandsProps): UECommands => {
  const { dataChannelRef, playerRef, videoRef } = props

  const { normalizeAndQuantizeUnsigned, normalizeAndQuantizeSigned } = useUEGeometry({
    playerRef,
    videoRef
  })

  const sendInputData: SendInputData = (data) => {
    const dc = dataChannelRef.current
    if (dc?.readyState === "open") {
      dc.send(data)
    } else {
      console.log("Cannot send data on data channel because readyState !== open", dc)
    }
  }

  const emitCode: EmitCode = (type) => {
    sendInputData(new Uint8Array([getMessageCode(type)]).buffer)
  }

  const emitDescriptor: EmitDescriptor = (type, descriptor) => {
    const messageCode = getMessageCode(type)
    // Convert the dscriptor object into a JSON string.
    const descriptorAsString = JSON.stringify(descriptor)

    // Add the UTF-16 JSON string to the array byte buffer, going two bytes at
    // a time.
    const data = new DataView(new ArrayBuffer(1 + 2 + 2 * descriptorAsString.length))
    let byteIdx = 0
    data.setUint8(byteIdx, messageCode)
    byteIdx++
    data.setUint16(byteIdx, descriptorAsString.length, true)
    byteIdx += 2
    for (let i = 0; i < descriptorAsString.length; i++) {
      data.setUint16(byteIdx, descriptorAsString.charCodeAt(i), true)
      byteIdx += 2
    }
    sendInputData(data.buffer)
  }

  const emitUECommand: EmitUECommand = (descriptor: UEDescriptor) => {
    emitDescriptor("Command", descriptor)
  }

  const emitUIInteraction: EmitUIInteraction = (descriptor: UEDescriptor) => {
    console.log("emit ui interaction", { descriptor })

    emitDescriptor("UIInteraction", descriptor)
  }

  const requestInitialSettings: RequestInitialSettings = () => {
    emitCode("RequestInitialSettings")
  }

  const requestQualityControl: RequestQualityControl = () => {
    emitCode("RequestQualityControl")
  }

  const emitMouseMove: EmitMouseMove = (x, y, deltaX, deltaY) => {
    const coord = normalizeAndQuantizeUnsigned(x, y)
    const delta = normalizeAndQuantizeSigned(deltaX, deltaY)
    if (!coord || !delta) return

    const Data = new DataView(new ArrayBuffer(9))
    Data.setUint8(0, getMessageCode("MouseMove"))
    Data.setUint16(1, coord.x, true)
    Data.setUint16(3, coord.y, true)
    Data.setInt16(5, delta.x, true)
    Data.setInt16(7, delta.y, true)
    sendInputData(Data.buffer)
  }

  const emitMouseDown: EmitMouseDown = (button, x, y) => {
    // eslint-disable-next-line no-constant-condition
    const coord = normalizeAndQuantizeUnsigned(x, y)
    if (!coord) return
    const Data = new DataView(new ArrayBuffer(6))
    Data.setUint8(0, getMessageCode("MouseDown"))
    Data.setUint8(1, button)
    Data.setUint16(2, coord.x, true)
    Data.setUint16(4, coord.y, true)
    sendInputData(Data.buffer)
  }

  const emitMouseUp: EmitMouseUp = (button, x, y) => {
    const coord = normalizeAndQuantizeUnsigned(x, y)
    if (!coord) return
    const Data = new DataView(new ArrayBuffer(6))
    Data.setUint8(0, getMessageCode("MouseUp"))
    Data.setUint8(1, button)
    Data.setUint16(2, coord.x, true)
    Data.setUint16(4, coord.y, true)
    sendInputData(Data.buffer)
  }

  const emitMouseEnter: EmitMouseEnter = () => {
    const Data = new DataView(new ArrayBuffer(1))
    Data.setUint8(0, getMessageCode("MouseEnter"))
    sendInputData(Data.buffer)
  }

  const emitMouseLeave: EmitMouseLeave = () => {
    const Data = new DataView(new ArrayBuffer(1))
    Data.setUint8(0, getMessageCode("MouseLeave"))
    sendInputData(Data.buffer)
  }

  const emitTouchData: EmitTouchData = (type, touches, fingerIds) => {
    const player = playerRef.current
    if (!player) return
    const data = new DataView(new ArrayBuffer(2 + 7 * touches.length))
    data.setUint8(0, type)
    data.setUint8(1, touches.length)
    let byte = 2
    for (let t = 0; t < touches.length; t++) {
      const touch = touches[t]
      const x = touch.clientX - player.offsetLeft
      const y = touch.clientY - player.offsetTop
      const fingerId = fingerIds[touch.identifier]
      const coord = normalizeAndQuantizeUnsigned(x, y)
      if (!coord) return
      data.setUint16(byte, coord.x, true)
      byte += 2
      data.setUint16(byte, coord.y, true)
      byte += 2
      data.setUint8(byte, fingerId)
      byte += 1
      data.setUint8(byte, Math.round(255 * touch.force)) // force is between 0.0 and 1.0 so quantize into byte.
      byte += 1
      data.setUint8(byte, coord.inRange ? 1 : 0)
      byte += 1
    }
    sendInputData(data.buffer)
  }

  return {
    emitUECommand,
    emitUIInteraction,
    requestInitialSettings,
    requestQualityControl,
    emitMouseMove,
    emitMouseDown,
    emitMouseUp,
    emitMouseEnter,
    emitMouseLeave,
    emitTouchData
  }
}
