import React, { PropsWithChildren, RefObject, useContext, useRef, useState } from "react"
import { EventEmitter } from "stream"
import { useSignalling } from "../pixelStreaming/signalling"
import { UECommands, useUECommands } from "../pixelStreaming/ue4/commands"
import { useUEEvents } from "../pixelStreaming/ue4/events"
// import { useUEMouseEvents } from "../pixelStreaming/ue4/mouse"
import { useNativeTouchEvents } from "../pixelStreaming/ue4/touch"
import { useUE4Video } from "../pixelStreaming/ue4/video"
import { useWebRTC } from "../pixelStreaming/webrtc"
import { AuthContext } from "./AuthContext"

export interface IPixelStreamingContext {
  videoRef: RefObject<HTMLVideoElement>
  playerRef: RefObject<HTMLDivElement>
  ueCommands: UECommands
  webrtcReady: boolean
  isDataChannelOpen: boolean
  isVideoInitialized: boolean
  connect: () => Promise<void>
  responseEmitter: EventEmitter
}

const DEFAULT_VALUE = {
  videoRef: null,
  playerRef: null,
  ueCommands: null,
  isDataChannelOpen: false,
  isVideoInitialized: false
} as unknown as IPixelStreamingContext

export const PixelStreamingContext = React.createContext<IPixelStreamingContext>(DEFAULT_VALUE)

export const PixelStreamingContextProvider = (props: PropsWithChildren<unknown>): JSX.Element => {
  const { tokenData, token } = useContext(AuthContext)

  const [isVideoInitialized, setIsVideoInitialized] = useState(false)

  const videoRef = useRef<HTMLVideoElement>(null)
  const playerRef = useRef<HTMLDivElement>(null)

  const { emitter, onIceCandidate, onOfferCreated } = useSignalling({ endpoint: tokenData?.signalling_endpoint, token })

  const onVideoInitialized = () => {
    setIsVideoInitialized(true)
    ueCommands.requestInitialSettings()
    ueCommands.requestQualityControl()
  }

  const { onTrack } = useUE4Video({ videoRef, onVideoInitialized })

  const { onDataChannelMessage, responseEmitter } = useUEEvents()

  const { isDataChannelOpen, dataChannelRef, connect, webrtcReady } = useWebRTC({
    emitter,
    onIceCandidate,
    onOfferCreated,
    onTrack,
    onDataChannelMessage
  })

  const ueCommands = useUECommands({ dataChannelRef, playerRef, videoRef })

  // useUEMouseEvents({ playerRef, ueCommands })
  useNativeTouchEvents({ playerRef, ueCommands })

  const value = {
    videoRef,
    playerRef,
    ueCommands,
    webrtcReady,
    isDataChannelOpen,
    isVideoInitialized,
    connect,
    responseEmitter
  }

  return <PixelStreamingContext.Provider value={value}>{props.children}</PixelStreamingContext.Provider>
}
