import React, { useContext, useMemo } from "react"
import styled from "styled-components"
import { CRMContext, GalleryItemType } from "../../contexts/CRMContext"
import { FavoritesContext } from "../../contexts/FavoritesContext"
import { NEW_WINDOW, ShowroomWindowContext } from "../../contexts/ShowroomWindowContext"
import { FavoriteIdentifier } from "../../hooks/useFavoritesReducer"
import { useSwitchGroup } from "../../hooks/useSwitchGroup"
import { FilterField, FilterFieldSet, FiltersBar, Toggle } from "../FiltersBar"
import { GalleryCard } from "../GalleryCard"

type FilterOption = { key: string; title: string }
const FILTER_OPTION_COMMON_SPACES: FilterOption = { key: "common_spaces", title: "Espaces Communs" }
const FILTER_OPTION_PRIVATE_SPACES: FilterOption = { key: "private_spaces", title: "Espaces Privés" }

const FILTER_OPTIONS = [FILTER_OPTION_COMMON_SPACES, FILTER_OPTION_PRIVATE_SPACES]

const GalleryContainer = styled.div`
  /* background-color: rgba(255, 246, 241, 0.85); */
  height: 100%;
`

const CardListWrapper = styled.div`
  padding: 16px 10px;
`

const CardListGrid = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`

const CardList = (props: { items: GalleryItemType[] }): JSX.Element => {
  const windowCtx = useContext(ShowroomWindowContext)
  const { favorites, toggleFavorite } = useContext(FavoritesContext)

  const handleClick = (item: GalleryItemType) => {
    windowCtx.addWindow({ ...NEW_WINDOW.gallery_viewer, title: item.title }, item)
  }

  return (
    <CardListWrapper>
      <CardListGrid>
        {props.items.map((gi) => {
          const footerText = gi.meta.tags.filter((t) => t !== "app_gallery").join("/")
          const favoriteID: FavoriteIdentifier = `gallery:${gi.id}`
          const isFavorite = favorites.has(favoriteID)
          return (
            <GalleryCard
              key={gi.id}
              imageUrl={gi.meta.download_url}
              title={gi.title}
              footerText={footerText}
              onClick={() => handleClick(gi)}
              selected={isFavorite}
              onHeartClick={() => toggleFavorite(favoriteID)}
            />
          )
        })}
      </CardListGrid>
    </CardListWrapper>
  )
}

const Gallery = (): JSX.Element => {
  const crmCtx = useContext(CRMContext)

  const { allChecked, switchesValues, onSwitchChange } = useSwitchGroup({ keys: FILTER_OPTIONS.map((o) => o.key) })

  const filteredGallery = useMemo<GalleryItemType[]>(() => {
    if (allChecked) return crmCtx.data.gallery || []
    return (crmCtx.data.gallery || []).filter((item) => {
      if (item.meta.tags.includes("Espace commun")) return switchesValues.get(FILTER_OPTION_COMMON_SPACES.key) === true
      else {
        return switchesValues.get(FILTER_OPTION_PRIVATE_SPACES.key) === true
      }
    })
  }, [crmCtx.data.gallery, switchesValues, allChecked])

  return (
    <GalleryContainer>
      <FiltersBar>
        <FilterFieldSet>
          <FilterField title="Tout">
            <Toggle checked={allChecked} onChange={onSwitchChange} id="all" />
          </FilterField>
          {FILTER_OPTIONS.map((o) => {
            return (
              <FilterField key={o.key} title={o.title}>
                <Toggle checked={switchesValues.get(o.key) || false} onChange={onSwitchChange} id={o.key} />
              </FilterField>
            )
          })}
        </FilterFieldSet>
      </FiltersBar>
      <CardList items={filteredGallery} />
    </GalleryContainer>
  )
}

export default Gallery
