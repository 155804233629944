import { useContext } from "react"
import { ALL_LEVELS, EXTERIOR_ONLY, INTERIOR_ONLY, ShowroomLevelContext } from "../../contexts/ShowroomLevelContext"
import { ShowroomSessionContext } from "../../contexts/ShowroomSessionContext"
import { NEW_WINDOW, ShowroomWindowContext } from "../../contexts/ShowroomWindowContext"
import { DockItem } from "../Dock"
import { IconType } from "../Icon"

export const useDockItems = (): DockItem[] => {
  const sessionCtx = useContext(ShowroomSessionContext)
  const levelCtx = useContext(ShowroomLevelContext)
  const windowCtx = useContext(ShowroomWindowContext)

  const items: DockItem[] = [
    {
      title: "Accueil",
      icon: IconType.vivenda,
      levelKinds: ALL_LEVELS,
      onClick: () => {
        const currentLevel = levelCtx.selectedLevel
        if (!currentLevel) return
        levelCtx.changeLevel("exterior_0")
        if (currentLevel.kind === "interior") {
          windowCtx.closeAll()
        }
      }
    },
    {
      title: "Environnant",
      icon: IconType.around,
      levelKinds: ALL_LEVELS,
      onClick: () => windowCtx.addWindow(NEW_WINDOW.around)
    },
    {
      title: "Gallerie",
      icon: IconType.gallery,
      levelKinds: ALL_LEVELS,
      onClick: () => windowCtx.addWindow(NEW_WINDOW.gallery)
    },
    {
      title: "Favoris",
      icon: IconType.heart,
      levelKinds: ALL_LEVELS,
      onClick: () => windowCtx.addWindow(NEW_WINDOW.favorites)
    },
    {
      title: "Commodités",
      icon: IconType.people,
      levelKinds: ALL_LEVELS,
      onClick: () => windowCtx.addWindow(NEW_WINDOW.amenities)
    },
    {
      title: "Unités témoin",
      icon: IconType.unit_interior,
      levelKinds: ALL_LEVELS,
      onClick: () => {
        if (levelCtx.selectedLevel?.kind !== "interior") {
          levelCtx.changeLevel("interior_0")
        }

        windowCtx.closeAll()
        windowCtx.addWindow(NEW_WINDOW.template_units)
        windowCtx.addWindow(NEW_WINDOW.teleportation)
      }
    },
    {
      title: "Personnalisation",
      icon: IconType.gear,
      levelKinds: INTERIOR_ONLY,
      onClick: () => windowCtx.addWindow(NEW_WINDOW.interior_customization)
    },
    {
      title: "Filtres",
      icon: IconType.filters,
      levelKinds: EXTERIOR_ONLY,
      onClick: () => windowCtx.addWindow(NEW_WINDOW.filters)
    },
    {
      title: "Sortie",
      icon: IconType.exit,
      levelKinds: ALL_LEVELS,
      onClick: () => {
        sessionCtx.setIsExiting(true)
      }
    }
  ]

  const selectedLevel = levelCtx.selectedLevel
  if (!selectedLevel) return []
  return items.filter((item) => item.levelKinds.has(selectedLevel.kind))
}
