import React, { useContext } from "react"
import styled from "styled-components"
import { UnitSchema } from "../../contexts/CRMContext"
import { FavoritesContext } from "../../contexts/FavoritesContext"
import { ShowroomWindows } from "../../contexts/ShowroomWindowContext"
import { FavoriteIdentifier } from "../../hooks/useFavoritesReducer"
import { IWindow } from "../../WindowManager/WindowManagerContext"
import { Button } from "../Button"
import { IconType } from "../Icon"

const Wrapper = styled.div<{ image_url?: string }>`
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: ${(props) => `url("${props.image_url}")`};
  background-color: black;
`

const FavoriteButtonWrapper = styled.div`
  position: absolute;
  right: 10px;
  bottom: 48px;
`

const PlanViewer = (props: { window: IWindow<ShowroomWindows> }): JSX.Element => {
  const parsed = UnitSchema.parse(props.window.data)
  const favoriteID: FavoriteIdentifier = `unit:${parsed.id}`
  const { favorites, toggleFavorite } = useContext(FavoritesContext)

  const selected = favorites.has(favoriteID)

  const handleHeartClick = () => {
    toggleFavorite(favoriteID)
  }

  return (
    <Wrapper image_url={parsed.plan_image_url || ""}>
      <FavoriteButtonWrapper>
        <Button
          icon={IconType.heart}
          intent="primary"
          selected={selected}
          selectedColor="#FF3B30"
          onClick={handleHeartClick}
        />
      </FavoriteButtonWrapper>
    </Wrapper>
  )
}

export default PlanViewer
