import { range } from "lodash"
import React, { useContext } from "react"
import styled from "styled-components"
import { Scene } from "../../const/servers"
import { AuthContext } from "../../contexts/AuthContext"
import { PixelStreamingContext } from "../../contexts/PixelStreamingContext"
import { ShowroomLevel, ShowroomLevelContext } from "../../contexts/ShowroomLevelContext"
import { Icon, IconType } from "../Icon"

const Container = styled.div`
  background-color: rgba(6, 74, 95, 1);
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: calc(100% - 20px);
`

const Item = styled.div`
  background-color: #f4eeea;
  display: flex;
  justify-content: center;
  padding: 8px;
  border-radius: 8px;
  color: #0d4657;
  font-weight: 700;
  cursor: pointer;
  flex-grow: 1;
  align-items: center;
  gap: 12px;
`

const ItemGroup = styled.div`
  display: flex;
  gap: 8px;
  justify-content: stretch;
`

type TeleportationItemKind = "living_room" | "kitchen" | "dining_room" | "bedroom" | "bathroom" | "game_room"
const UNIT_ITEM_KINDS: TeleportationItemKind[] = ["living_room", "kitchen", "dining_room", "bedroom", "bathroom"]
const CHALET_ITEM_KINDS: TeleportationItemKind[] = ["living_room", "kitchen", "game_room"]

const ROOM_TITLES: { [K in TeleportationItemKind]: string } = {
  living_room: "Salon",
  kitchen: "Cuisine",
  dining_room: "Salle à diner",
  bedroom: "Chambre",
  bathroom: "Salle de bain",
  game_room: "Salle de jeu"
}

const ROOM_ICONS: { [K in TeleportationItemKind]: IconType } = {
  living_room: IconType.sofa,
  kitchen: IconType.fridge,
  dining_room: IconType.dining,
  bedroom: IconType.bed,
  bathroom: IconType.shower,
  game_room: IconType.people
}

type TeleportationItem = {
  kind: TeleportationItemKind
  count?: number
}

const useTeleportationItems = (level?: ShowroomLevel): TeleportationItem[] => {
  const authCtx = useContext(AuthContext)

  if (level?.kind === "interior") {
    if (!level.unit) return []
    const { unit } = level
    const items = UNIT_ITEM_KINDS.map((k) => {
      if (k === "bedroom") return { kind: k, count: unit.bedrooms }
      if (k === "bathroom") return { kind: k, count: 1 }
      return { kind: k }
    })

    return items
  }

  if (authCtx.tokenData?.scene_id && authCtx.tokenData.scene_id !== Scene.cloud) {
    if (level?.kind === "exterior") {
      return CHALET_ITEM_KINDS.map((x) => ({ kind: x }))
    }
  }

  return []
}

const Teleportation = (): JSX.Element => {
  const pixelCtx = useContext(PixelStreamingContext)
  const levelCtx = useContext(ShowroomLevelContext)
  const items = useTeleportationItems(levelCtx.selectedLevel)

  const handleTeleportationClick = (kind: string, index?: number) => {
    pixelCtx.ueCommands.emitUIInteraction({
      type: "teleportation",
      kind,
      index
    })
  }

  return (
    <Container>
      {items.map((item) => {
        const title = ROOM_TITLES[item.kind]
        const icon = ROOM_ICONS[item.kind]
        if (item.count) {
          const showsTitle = item.count < 3
          const showsIndex = item.count > 1
          return (
            <ItemGroup key={item.kind}>
              {range(0, item.count).map((i) => {
                return (
                  <Item key={i} onClick={() => handleTeleportationClick(item.kind, i)}>
                    <span>{`${showsTitle ? title : ""} ${showsIndex ? `0${i + 1}` : ""}`}</span>
                    <Icon type={icon} size={20} color="#0D4657" />
                  </Item>
                )
              })}
            </ItemGroup>
          )
        } else {
          return (
            <Item key={item.kind} onClick={() => handleTeleportationClick(item.kind)}>
              <span>{title}</span>
              <Icon type={icon} size={20} color="#0D4657" />
            </Item>
          )
        }
      })}
    </Container>
  )
}

export default Teleportation
