import { MutableRefObject, useCallback, useEffect, useRef, useState } from "react"
import { useSignalling } from "./signalling"

export type UseWebRTCProps = ReturnType<typeof useSignalling> & {
  onTrack: (ev: RTCTrackEvent) => void
  onDataChannelMessage: (ev: MessageEvent<ArrayBuffer>) => void
}

export interface IWebRTC {
  peerConnectionRef: MutableRefObject<RTCPeerConnection | null>
  dataChannelRef: MutableRefObject<RTCDataChannel | null>
  webrtcReady: boolean
  isDataChannelOpen: boolean
  connect: () => Promise<void>
}

const DEFAULT_RTC_CONFIG: RTCConfiguration = {
  iceServers: [{ urls: "stun:stun.l.google.com:19302" }]
}

const DEFAULT_OFFER_CONFIG: RTCOfferOptions = {
  offerToReceiveVideo: true
}

export const useWebRTC = (props: UseWebRTCProps): IWebRTC => {
  const { onIceCandidate, onOfferCreated, onTrack, onDataChannelMessage, emitter } = props

  const peerConnectionRef = useRef<RTCPeerConnection | null>(null)
  const dataChannelRef = useRef<RTCDataChannel | null>(null)

  const [webrtcReady, setWebrtcReady] = useState(false)
  const [isDataChannelOpen, setIsDataChannelOpen] = useState(false)

  const setupPeerConnection = () => {
    const config = DEFAULT_RTC_CONFIG

    const pc = new RTCPeerConnection(config)

    pc.addTransceiver("video", { direction: "recvonly" })

    pc.onicecandidate = onIceCandidate
    pc.ontrack = onTrack
    pc.onsignalingstatechange = (...args) => console.log("onsignalingstatechange", args)
    pc.oniceconnectionstatechange = (...args) => console.log("oniceconnectionchange", args)
    pc.onicegatheringstatechange = (...args) => console.log("onicegatheringchange", args)
    pc.ondatachannel = () => console.log("Data channel...")
    pc.onicecandidateerror = (ev) => console.log("Ice candidate error:", ev)

    return pc
  }

  const connect = async () => {
    if (!webrtcReady) return
    const pc = setupPeerConnection()
    setupDataChannel(pc)
    await createOffer(pc)
    peerConnectionRef.current = pc
  }

  const subscribeToSignalling = (): (() => void) => {
    if (emitter.listenerCount("webrtcReady") > 0) {
      return () => undefined
    }

    const onwebrtcready = async () => {
      setWebrtcReady(true)
    }
    const onanswer = async ({ sdp }: { sdp: string }) => onRemoteAnswer({ sdp, type: "answer" })
    const onicecandidate = ({ candidate }: { candidate: RTCIceCandidateInit }) => onRemoteIceCandidate(candidate)
    emitter.addListener("webrtcReady", onwebrtcready)
    emitter.addListener("answer", onanswer)
    emitter.addListener("iceCandidate", onicecandidate)

    return () => {
      emitter.removeListener("webrtcReady", onwebrtcready)
      emitter.removeListener("answer", onanswer)
      emitter.removeListener("iceCandidate", onicecandidate)
    }
  }

  const setupDataChannel = (pc: RTCPeerConnection) => {
    if (dataChannelRef.current) return console.log("data channel is already here.")

    const dataChannel = pc.createDataChannel("cirrus", { ordered: true })
    dataChannel.binaryType = "arraybuffer"
    dataChannel.onerror = (e) => console.log("datachannel error", e)

    dataChannel.onopen = onDataChannelOpen
    dataChannel.onclose = onDataChannelClose
    dataChannel.onmessage = onDataChannelMessage

    dataChannelRef.current = dataChannel

    return dataChannel
  }

  const createOffer = useCallback(
    async (pc: RTCPeerConnection) => {
      const offer = await pc.createOffer(DEFAULT_OFFER_CONFIG)
      await pc.setLocalDescription(offer)

      onOfferCreated(offer)
    },
    [onOfferCreated]
  )

  const onDataChannelOpen = () => {
    console.log("data channel open")
    setIsDataChannelOpen(true)
  }

  const onDataChannelClose = () => {
    console.log("data channel close")
    setIsDataChannelOpen(false)
    dataChannelRef.current = null
  }

  const onRemoteAnswer = async (answer: RTCSessionDescriptionInit): Promise<void> => {
    const pc = peerConnectionRef.current
    if (!pc) return
    try {
      await pc.setRemoteDescription(new RTCSessionDescription({ type: "answer", sdp: answer.sdp }))
      console.log("successfully set remote description")
    } catch (e) {
      console.log("error setting remote description", e)
    }
  }

  const onRemoteIceCandidate = async (iceCandidate: RTCIceCandidateInit) => {
    const pc = peerConnectionRef.current
    if (!pc) return
    try {
      await pc.addIceCandidate(new RTCIceCandidate(iceCandidate))
      console.log("successfully added ice candidate")
    } catch (e) {
      console.log("error adding ice candidate", e)
    }
  }

  useEffect(() => {
    const unsubscribe = subscribeToSignalling()
    return () => {
      unsubscribe()
    }
  }, [])

  return {
    peerConnectionRef,
    dataChannelRef,
    isDataChannelOpen,
    connect,
    webrtcReady
  }
}
