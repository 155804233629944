import React, { useContext } from "react"
import styled from "styled-components"
import { ShowroomLevelContext } from "../../contexts/ShowroomLevelContext"
import { UnitCard } from "../UnitCard"

const Container = styled.div`
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const TemplateUnits = (): JSX.Element => {
  const { interior } = useContext(ShowroomLevelContext)

  return (
    <Container>
      {interior.map((level, i) => {
        return (
          <UnitCard
            key={level.id}
            unit={level.unit}
            level={level}
            collapsible
            defaultOpen={i === 0}
            actions={new Set(["blueprint", "visit", "favorite_toggle"])}
          />
        )
      })}
    </Container>
  )
}

export default TemplateUnits
