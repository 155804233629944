import React, { PropsWithChildren, useMemo } from "react"
import { z } from "zod"
import { useCRMGet } from "../hooks/useCRMGet"

export interface ICRMContext {
  loading?: boolean
  ready: boolean
  data: {
    salespeople: SalespeopleType[] | null
    units: UnitType[] | null
    gallery: GalleryItemType[] | null
  }
  maps: {
    units: Map<string, UnitType>
    gallery: Map<string, GalleryItemType>
  }
}

const DEFAULT_VALUE: ICRMContext = {
  loading: false,
  ready: false,
  data: {
    salespeople: null,
    units: null,
    gallery: null
  },
  maps: {
    units: new Map(),
    gallery: new Map()
  }
}

export const CRMContext = React.createContext<ICRMContext>(DEFAULT_VALUE)

export const SalespeopleSchema = z.object({
  id: z.number().int(),
  first_name: z.string(),
  last_name: z.string(),
  email: z.string().email(),
  telephone: z.string()
})

export const UnitSchema = z.object({
  id: z.number(),
  category: z.string(),
  floor: z.number().int(),
  surface: z.number(),
  phase: z.number(),
  price: z.number(),
  status: z.string(),
  size: z.string(),
  orientation: z.array(z.enum(["nord", "est", "sud", "ouest"])),
  view: z.array(z.string()),
  exterior: z.array(z.string()),
  plan_image_url: z.string().nullable().optional()
})

export const GalleryItemSchema = z.object({
  id: z.number(),
  title: z.string(),
  meta: z.object({
    tags: z.array(z.string()),
    download_url: z.string().url()
  })
})

const GalleryResultSchema = z.object({
  items: z.array(GalleryItemSchema)
})

export type SalespeopleType = z.infer<typeof SalespeopleSchema>
export type UnitType = z.infer<typeof UnitSchema> & { bedrooms: 0 | 1 | 2 | 3 }
export type GalleryItemType = z.infer<typeof GalleryItemSchema>

const bedroomCountFromCategory = (category: string): 0 | 1 | 2 | 3 => {
  const [size] = category.split("-")
  switch (size) {
    case "3.5":
      return 1
    case "4.5":
      return 2
    case "5.5":
      return 3
    case "Studio":
      return 0
    default:
      return 0
  }
}

const transformUnit = (unit: z.infer<typeof UnitSchema>): UnitType => {
  const { category } = unit
  const bedrooms = bedroomCountFromCategory(category)
  return {
    ...unit,
    bedrooms
  }
}

export const CRMContextProvider = (props: PropsWithChildren<unknown>): JSX.Element => {
  const { data: salespeople } = useCRMGet({ path: "/v1/salespeople/", schema: z.array(SalespeopleSchema) })
  const { data: unitData } = useCRMGet({ path: "/v1/units/", schema: z.array(UnitSchema) })
  const { data: galleryData } = useCRMGet({
    path: "/v2/images/",
    schema: GalleryResultSchema,
    query: { tags: "app_gallery", limit: "100" }
  })

  console.log({ galleryData })

  const units = useMemo(() => {
    return unitData?.map((u) => transformUnit(u)) || null
  }, [unitData])

  const ready = useMemo(() => {
    return !!salespeople && !!units && !!galleryData
  }, [salespeople, units, galleryData])

  const unitsMap = useMemo(() => {
    return (units || []).reduce((map, val) => {
      return map.set(val.id.toString(), val)
    }, new Map<string, UnitType>())
  }, [units])

  const galleryMap = useMemo(() => {
    return (galleryData?.items || []).reduce((map, val) => {
      return map.set(val.id.toString(), val)
    }, new Map<string, GalleryItemType>())
  }, [galleryData])

  const value = {
    ready,
    data: {
      salespeople,
      units,
      gallery: galleryData?.items || null
    },
    maps: {
      units: unitsMap,
      gallery: galleryMap
    }
  }
  return <CRMContext.Provider value={value}>{props.children}</CRMContext.Provider>
}
