import { RefObject, useEffect, useRef } from "react"
import { UECommands } from "./commands"
import { UEEmitMessageCodes } from "./messageCodes"

const MouseButton = {
  MainButton: 0, // Left button.
  AuxiliaryButton: 1, // Wheel button.
  SecondaryButton: 2, // Right button.
  FourthButton: 3, // Browser Back button.
  FifthButton: 4 // Browser Forward button.
}

export type UseTouchEventsProps = {
  playerRef: RefObject<HTMLDivElement>
  ueCommands: UECommands
}

type Finger = {
  id: number
  x: number
  y: number
}

export const useFakeTouchEvents = (props: UseTouchEventsProps): void => {
  const commands = props.ueCommands

  const finger = useRef<Finger | null>(null)

  const registerEvents = () => {
    const player = props.playerRef.current
    if (!player) return
    player.ontouchstart = (ev) => {
      const player = props.playerRef.current
      if (!player) return

      if (!finger.current) {
        const firstTouch = ev.changedTouches[0]
        const x = firstTouch.clientX - player.getBoundingClientRect().left
        const y = firstTouch.clientY - player.getBoundingClientRect().top
        finger.current = {
          id: firstTouch.identifier,
          x,
          y
        }
        if (player.onmouseenter) {
          player.onmouseenter(ev as unknown as MouseEvent)
        }
        commands.emitMouseDown(MouseButton.MainButton, finger.current.x, finger.current.y)
      }

      ev.preventDefault()
    }

    player.ontouchend = (ev) => {
      const player = props.playerRef.current
      if (!player) return
      for (const touch of ev.changedTouches) {
        if (touch.identifier === finger.current?.id) {
          const x = touch.clientX - player.getBoundingClientRect().left
          const y = touch.clientY - player.getBoundingClientRect().top
          commands.emitMouseUp(MouseButton.MainButton, x, y)
          if (player.onmouseleave) {
            player.onmouseleave(ev as unknown as MouseEvent)
          }

          finger.current = null
        }
        break
      }

      ev.preventDefault()
    }

    player.ontouchmove = (ev) => {
      const player = props.playerRef.current
      if (!player) return
      for (const touch of ev.touches) {
        if (touch.identifier === finger.current?.id) {
          const f = finger.current
          if (!f) return
          const x = touch.clientX - player.getBoundingClientRect().left
          const y = touch.clientY - player.getBoundingClientRect().top

          const movX = x - f.x
          const movY = y - f.y
          commands.emitMouseMove(x, y, movX, movY)
          finger.current.x = x
          finger.current.y = y
          break
        }
      }

      ev.preventDefault()
    }
  }

  useEffect(() => {
    registerEvents()
  }, [])
}

export const useNativeTouchEvents = (props: UseTouchEventsProps): void => {
  const commands = props.ueCommands
  const fingers = useRef([9, 8, 7, 6, 5, 4, 3, 2, 1, 0])
  const fingerIds = useRef<{ [S: string]: number }>({})

  const rememberTouch = (touch: Touch) => {
    const finger = fingers.current.pop()
    if (finger === undefined) {
      console.log("exhausted touch ids")
      return
    }

    fingerIds.current[touch.identifier] = finger
  }

  const forgetTouch = (touch: Touch) => {
    const id = fingerIds.current[touch.identifier]
    const newArray = [...fingers.current]
    newArray.push(id)
    fingers.current = newArray.sort((a, b) => b - a)
    delete fingerIds.current[touch.identifier]
  }

  const touchStart = (e: TouchEvent) => {
    console.log("Touch start")

    if (e.currentTarget !== e.target) {
      return
    }

    // Assign a unique identifier to each touch.
    for (let t = 0; t < e.changedTouches.length; t++) {
      rememberTouch(e.changedTouches[t])
    }

    commands.emitTouchData(UEEmitMessageCodes["TouchStart"], e.changedTouches, fingerIds.current)
    e.preventDefault()
  }

  const touchEnd = (e: TouchEvent) => {
    if (e.currentTarget !== e.target) {
      return
    }

    commands.emitTouchData(UEEmitMessageCodes["TouchEnd"], e.changedTouches, fingerIds.current)

    // Re-cycle unique identifiers previously assigned to each touch.
    for (let t = 0; t < e.changedTouches.length; t++) {
      forgetTouch(e.changedTouches[t])
    }
    e.preventDefault()
  }

  const touchMove = (e: TouchEvent) => {
    console.log("Touch move")
    commands.emitTouchData(UEEmitMessageCodes["TouchMove"], e.touches, fingerIds.current)
    e.preventDefault()
  }

  const registerEvents = () => {
    const player = props.playerRef.current
    if (!player) return console.log("No player, cannot register events")

    player.addEventListener("touchstart", touchStart)

    player.addEventListener("touchend", touchEnd)

    player.addEventListener("touchmove", touchMove)
  }

  useEffect(() => {
    registerEvents()
  }, [])
}
