import React from "react"
import styled, { css } from "styled-components"

const SegmentControlContainer = styled.div`
  display: flex;
  justify-content: stretch;
  /* padding: 0px 20px; */
  gap: 4px;
  flex-grow: 0.67;
`
const SegmentItem = styled.div<{ selected?: boolean }>`
  padding: 8px 12px;
  flex-grow: 1;
  font-size: 14px;
  cursor: pointer;
  border-radius: 4px;
  text-align: center;

  ${(props) =>
    props.selected
      ? css`
          background-color: #ff8137;
          color: white;
        `
      : css`
          background-color: #e5e5e5;
          color: black;
        `}
`

export type SegmentControlItem<T extends string | number> = {
  id: T
  title: string
}
export type SegmentControlProps<ItemType extends string | number> = {
  items: SegmentControlItem<ItemType>[]
  multi?: boolean
  defaultSelected: Set<ItemType>
  selectedValues: Set<ItemType>
  onChange: (selectedValues: Set<ItemType>) => void
}
export const SegmentControl = <ItemType extends string | number>(props: SegmentControlProps<ItemType>): JSX.Element => {
  const { onChange, selectedValues, multi } = props

  const onClick = (item: ItemType) => {
    if (multi) {
      if (selectedValues.has(item)) {
        const newSet = new Set([...selectedValues].filter((i) => i !== item))
        onChange(newSet)
      } else {
        onChange(new Set([...selectedValues, item]))
      }
    } else {
      onChange(new Set([item]))
    }
  }

  return (
    <SegmentControlContainer>
      {props.items.map((item) => {
        return (
          <SegmentItem key={item.id} selected={selectedValues.has(item.id)} onClick={() => onClick(item.id)}>
            {item.title}
          </SegmentItem>
        )
      })}
    </SegmentControlContainer>
  )
}
