import React, { PropsWithChildren } from "react"
import styled, { css } from "styled-components"
import { ModalButtonProps, ModalProps, MODAL_BUTTON_STYLES } from "./Modal"

const Wrapper = styled.div`
  background-color: rgba(13, 70, 87, 0.8);
  backdrop-filter: blur(4px);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Container = styled.div`
  background-color: rgba(13, 70, 87, 0.8);
  min-width: 60%;
  max-width: 80%;
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.3);
`

const Header = styled.h3`
  color: white;
  text-align: center;
  margin: 12px 0px;
`

const Content = styled.div`
  color: white;
  padding: 8px 15%;
  text-align: center;
`

const ActionBar = styled.div`
  display: flex;
  padding: 12px;
  gap: 8px;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
`

const ModalButton = styled.button<ModalButtonProps>`
  flex-grow: 1;
  border: none;
  padding: 8px 12px;
  font-size: 16px;
  border-radius: 8px;
  font-weight: 600;
  ${(props) => {
    const colors = MODAL_BUTTON_STYLES[props.intent]
    return css`
      background-color: ${colors.backgroundColor};
      color: ${colors.foregroundColor};
    `
  }};

  cursor: pointer;
`

export const WindowedModal = (props: PropsWithChildren<ModalProps>): JSX.Element | null => {
  const { title, actions } = props
  if (!props.open) {
    return null
  }

  return (
    <Wrapper>
      <Container>
        <Header>{title}</Header>
        <Content>{props.children}</Content>
        <ActionBar>
          {actions.map((a) => {
            return (
              <ModalButton key={a.title} intent={a.intent} onClick={a.onClick}>
                {a.title}
              </ModalButton>
            )
          })}
        </ActionBar>
      </Container>
    </Wrapper>
  )
}
