import React from "react"
import SVG from "react-inlinesvg"

export enum IconType {
  arrow_right_circle = "arrow_right_circle",
  heart = "heart",
  around = "around",
  gallery = "gallery",
  people = "people",
  filters = "filters",
  mail = "mail",
  user_settings = "user_settings",
  vivenda = "vivenda",
  close = "close",
  unit_interior = "unit_interior",
  trash = "trash",
  bed = "bed",
  shower = "shower",
  compass = "compass",
  building = "building",
  collapsible_plus = "collapsible_plus",
  collapsible_minus = "collapsible_minus",
  gear = "gear",
  sofa = "sofa",
  fridge = "fridge",
  dining = "dining",
  exit = "exit",
  resize = "resize"
}

export type IconProps = {
  type: IconType
  color: string
  size: number
  transform?: string
  stroke?: boolean
}

export const iconUrl = (type: IconType): string => {
  return `${process.env.PUBLIC_URL}/icons/${type}.svg`
}

export const Icon = (props: IconProps): JSX.Element => {
  const { size, color, transform, stroke } = props
  return (
    <SVG
      src={iconUrl(props.type)}
      width={size}
      height={size}
      color={color}
      fill={color}
      stroke={stroke ? color : undefined}
      transform={transform}
    ></SVG>
  )
}
