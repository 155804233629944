import React, { PropsWithChildren, useEffect, useMemo, useState } from "react"
import { z } from "zod"
import JWT from "jsonwebtoken"
import { useGetPixelTokenMutation } from "../generated/graphql"
import { Scene } from "../const/servers"

const TokenDataSchema = z.object({
  email: z.string(),
  session_id: z.string(),
  scene_id: z.nativeEnum(Scene),
  signalling_endpoint: z.string()
})

export type TokenData = z.infer<typeof TokenDataSchema>

export interface IAuthContext {
  login: ReturnType<typeof useGetPixelTokenMutation>[0]
  logout: () => void
  token: string | null
  tokenData: TokenData | null
  showPrices: boolean
  setShowPrices: (val: boolean) => void
}

const DEFAULT_VALUE: IAuthContext = {} as IAuthContext

export const AuthContext = React.createContext<IAuthContext>(DEFAULT_VALUE)

export const AuthContextProvider = (props: PropsWithChildren<unknown>): JSX.Element => {
  const [token, setToken] = useState<string | null>(null)
  const [showPrices, setShowPrices] = useState((localStorage.getItem("showPrices") || "true") === "true")

  const [login] = useGetPixelTokenMutation({
    onCompleted: (data) => {
      localStorage.setItem("token", data.getPixelToken)
      setToken(data.getPixelToken)
    },
    onError: () => {
      localStorage.removeItem("token")
      setToken(null)
    }
  })

  const logout = () => {
    localStorage.removeItem("token")
    setToken(null)
  }

  const tokenData = useMemo(() => {
    if (!token) return null
    try {
      const decoded = JWT.decode(token)
      const parsed = TokenDataSchema.parse(decoded)
      return parsed
    } catch (e) {
      return null
    }
  }, [token])

  useEffect(() => {
    localStorage.setItem("showPrices", showPrices ? "true" : "false")
  }, [showPrices])

  useEffect(() => {
    const token = localStorage.getItem("token")
    if (token && token.length) {
      setToken(token)
    }
  }, [])

  const value = {
    login,
    logout,
    token,
    tokenData,
    showPrices,
    setShowPrices
  }
  return <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
}
