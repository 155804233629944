import { Reducer, useContext, useEffect, useReducer } from "react"
import { PixelStreamingContext } from "../contexts/PixelStreamingContext"

export type FavoriteIdentifier = `${"unit" | "gallery"}:${string}`

export type FavoritesReducerAction =
  | { type: "toggle_favorite"; identifier: FavoriteIdentifier }
  | { type: "remove_favorite"; identifier: FavoriteIdentifier }
  | { type: "add_favorite"; identifier: FavoriteIdentifier }

const FAVORITES_REDUCER: Reducer<Set<FavoriteIdentifier>, FavoritesReducerAction> = (state, action) => {
  switch (action.type) {
    case "toggle_favorite": {
      if (state.has(action.identifier)) {
        return new Set([...state].filter((id) => id !== action.identifier))
      } else {
        return new Set([...state, action.identifier])
      }
    }
    case "remove_favorite": {
      return new Set([...state].filter((id) => id !== action.identifier))
    }
    case "add_favorite": {
      return new Set([...state, action.identifier])
    }
  }
}

export type UseFavoritesReducer = {
  favorites: Set<FavoriteIdentifier>
  toggleFavorite: (identifier: FavoriteIdentifier) => boolean
}

export const useFavoritesReducer = (): UseFavoritesReducer => {
  const pixelCtx = useContext(PixelStreamingContext)
  const [favorites, dispatchFavorites] = useReducer(FAVORITES_REDUCER, new Set<FavoriteIdentifier>())

  const sendFavoriteEvent = (id: FavoriteIdentifier, added: boolean) => {
    const [favType, itemId] = id.split(":")
    if (favType !== "unit") {
      return
    }

    const evType = added ? "favorite_added" : "favorite_removed"
    pixelCtx.ueCommands.emitUIInteraction({
      type: evType,
      unit_id: itemId
    })
  }

  const toggleFavorite = (identifier: FavoriteIdentifier) => {
    const added = !favorites.has(identifier)
    dispatchFavorites({ type: "toggle_favorite", identifier })
    sendFavoriteEvent(identifier, added)
    return added
  }

  useEffect(() => {
    pixelCtx.responseEmitter.on("toggle_favorite", (event) => {
      const { key, value } = event.data
      const identifier: FavoriteIdentifier = `unit:${key}`
      const type = value === "true" ? "add_favorite" : "remove_favorite"
      dispatchFavorites({ type, identifier })
    })
  }, [])

  return {
    favorites,
    toggleFavorite
  }
}
