import React, { useContext } from "react"
import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom"
import { CRMContextProvider } from "./contexts/CRMContext"
import { ShowroomSessionContextProvider } from "./contexts/ShowroomSessionContext"
import LoginPage from "./pages/Login"
import ShowroomPage from "./pages/ShowroomPage"
import { AuthContext, AuthContextProvider } from "./contexts/AuthContext"
import Config from "./config"

const httpLink = createHttpLink({
  uri: Config.REACT_APP_BACKEND_URL
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token")
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `JWT ${token}` : ""
    }
  }
})

const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
})

const ShowroomRouter = (): JSX.Element => {
  return (
    <Router>
      <Route path="/showroom" component={ShowroomPage} />
      <Route render={() => <Redirect to="/showroom" />} />
    </Router>
  )
}

const AppRouter = (): JSX.Element => {
  const authCtx = useContext(AuthContext)

  return (
    <Router>
      {!!authCtx.tokenData && (
        <CRMContextProvider>
          <ShowroomSessionContextProvider>
            <ShowroomRouter />
          </ShowroomSessionContextProvider>
        </CRMContextProvider>
      )}
      {!authCtx.tokenData && <LoginPage />}
    </Router>
  )
}

const App = (): JSX.Element => {
  return (
    <ApolloProvider client={apolloClient}>
      <div className="App">
        <AuthContextProvider>
          <AppRouter />
        </AuthContextProvider>
      </div>
    </ApolloProvider>
  )
}

export default App
