import React, { PropsWithChildren, Reducer, useContext, useEffect, useMemo, useReducer } from "react"
import { CRMContext, UnitType } from "./CRMContext"
import { PixelStreamingContext } from "./PixelStreamingContext"

export const MIN_FLOOR = 0
export const MAX_FLOOR = 12
export const MIN_SURFACE = 300
export const MAX_SURFACE = 1150
export const MIN_PRICE = 20000000
export const MAX_PRICE = 65000000

export type UnitFilters = {
  floorRange: [number, number]
  bedrooms: Set<0 | 1 | 2 | 3>
  surfaceRange: [number, number]
  priceRange: [number, number]
  orientation: Set<"nord" | "est" | "sud" | "ouest">
}

export const DEFAULT_FILTERS: UnitFilters = {
  floorRange: [MIN_FLOOR, MAX_FLOOR],
  bedrooms: new Set([0, 1, 2, 3]),
  surfaceRange: [MIN_SURFACE, MAX_SURFACE],
  priceRange: [MIN_PRICE, MAX_PRICE],
  orientation: new Set(["nord", "est", "sud", "ouest"])
}

export type UnitFiltersReducerAction =
  | { type: "set_floor_range"; floorRange: UnitFilters["floorRange"] }
  | { type: "set_bedrooms"; bedrooms: UnitFilters["bedrooms"] }
  | { type: "set_surface_range"; surfaceRange: UnitFilters["surfaceRange"] }
  | { type: "set_price_range"; priceRange: UnitFilters["priceRange"] }
  | { type: "set_orientation"; orientation: UnitFilters["orientation"] }
  | { type: "reset" }

const FILTER_REDUCER: Reducer<UnitFilters, UnitFiltersReducerAction> = (state, action) => {
  switch (action.type) {
    case "set_floor_range":
      return { ...state, floorRange: action.floorRange }
    case "set_bedrooms":
      return { ...state, bedrooms: action.bedrooms }
    case "set_surface_range":
      return { ...state, surfaceRange: action.surfaceRange }
    case "set_price_range":
      return { ...state, priceRange: action.priceRange }
    case "set_orientation": {
      return { ...state, orientation: action.orientation }
    }
    case "reset":
      return DEFAULT_FILTERS
    default:
      return state
  }
}

export interface UnitFiltersContextType {
  filters: UnitFilters
  dispatchFilters: React.Dispatch<UnitFiltersReducerAction>
  filteredUnits: UnitType[]
}

const DEFAULT_CONTEXT_VALUE: UnitFiltersContextType = {
  filters: DEFAULT_FILTERS,
  dispatchFilters: (state) => state,
  filteredUnits: [] as UnitType[]
}

export const UnitFiltersContext = React.createContext<UnitFiltersContextType>(DEFAULT_CONTEXT_VALUE)

export const UnitFiltersContextProvider = (props: PropsWithChildren<unknown>): JSX.Element => {
  const { ueCommands } = useContext(PixelStreamingContext)
  const { data } = useContext(CRMContext)
  const { units } = data
  const [filters, dispatchFilters] = useReducer(FILTER_REDUCER, DEFAULT_FILTERS)

  const filteredUnits = useMemo(() => {
    const filtered = (units || []).filter((unit) => {
      const [minFloor, maxFloor] = filters.floorRange
      const [minSurface, maxSurface] = filters.surfaceRange
      const [minPrice, maxPrice] = filters.priceRange

      const floorMatch = unit.floor >= minFloor && unit.floor <= maxFloor
      const bedroomMatch = filters.bedrooms.has(unit.bedrooms)
      const surfaceMatch = unit.surface >= minSurface && unit.surface <= maxSurface
      const priceMatch = unit.price >= minPrice && unit.price <= maxPrice
      const orientationMatch = unit.orientation.some((o) => filters.orientation.has(o))

      const fullMatch = [floorMatch, bedroomMatch, surfaceMatch, priceMatch, orientationMatch].every((match) => match)
      return fullMatch
    })

    return filtered
  }, [units, filters])

  useEffect(() => {
    ueCommands.emitUIInteraction({
      type: "units_filters_changed",
      unit_ids: filteredUnits.map((u) => u.id)
    })
  }, [filteredUnits])

  const value = {
    filters,
    dispatchFilters,
    filteredUnits
  }

  return <UnitFiltersContext.Provider value={value}>{props.children}</UnitFiltersContext.Provider>
}
