import React, { PropsWithChildren, useContext, useEffect, useMemo, useState } from "react"
import { CRMContext, UnitType } from "./CRMContext"
import { noop } from "./index"
import { PixelStreamingContext } from "./PixelStreamingContext"

export type InteriorLevelID = `interior_${number}`
export type ShowroomLevelID = "exterior_0" | InteriorLevelID
export type ShowroomLevelKind = "exterior" | "interior"

export const ALL_LEVELS: Set<ShowroomLevelKind> = new Set(["exterior", "interior"])
export const EXTERIOR_ONLY: Set<ShowroomLevelKind> = new Set(["exterior"])
export const INTERIOR_ONLY: Set<ShowroomLevelKind> = new Set(["interior"])

const LEVEL_KIND_INTERIOR: ShowroomLevelKind = "interior"
const LEVEL_KIND_EXTERIOR: ShowroomLevelKind = "exterior"

export interface IShowroomLevel {
  id: ShowroomLevelID
  kind: ShowroomLevelKind
}

export interface ExteriorShowroomLevel extends IShowroomLevel {
  id: "exterior_0"
  kind: typeof LEVEL_KIND_EXTERIOR
}

export interface InteriorShowroomLevel extends IShowroomLevel {
  id: InteriorLevelID
  kind: typeof LEVEL_KIND_INTERIOR
  unit: UnitType
}

export type ShowroomLevel = ExteriorShowroomLevel | InteriorShowroomLevel

const DEFAULT_EXTERIOR: ExteriorShowroomLevel = { id: "exterior_0", kind: LEVEL_KIND_EXTERIOR }

export interface IShowroomLevelContext {
  allLevels: ShowroomLevel[]
  exterior: ExteriorShowroomLevel
  interior: InteriorShowroomLevel[]
  selectedLevel?: ShowroomLevel
  changeLevel: (id: ShowroomLevelID) => void
  isLoadingLevel: boolean
  isStreamingLevel: boolean
}

const TEMPLATE_UNIT_IDS = new Set([816, 817, 827])

type UseShowroomLevelsProps = {
  units: UnitType[]
}

const DEFAULT_VALUE: IShowroomLevelContext = {
  allLevels: [],
  exterior: DEFAULT_EXTERIOR,
  interior: [],
  changeLevel: noop,
  isLoadingLevel: true,
  isStreamingLevel: true
}

export const ShowroomLevelContext = React.createContext(DEFAULT_VALUE)

export const ShowroomLevelContextProvider = (props: PropsWithChildren<unknown>): JSX.Element => {
  const crmCtx = useContext(CRMContext)
  const pixelCtx = useContext(PixelStreamingContext)

  const [isLoadingLevel, setIsLoadingLevel] = useState(false)
  const [isStreamingLevel, setIsStreamingLevel] = useState(false)

  const { interior, exterior, all: allLevels } = useShowroomLevels({ units: crmCtx.data.units || [] })

  const [selectedLevelId, setSelectedLevelId] = useState<ShowroomLevelID>(exterior.id)

  const selectedLevel = useMemo(() => {
    return allLevels.find((l) => l.id === selectedLevelId)
  }, [selectedLevelId])

  const changeLevel = (id: ShowroomLevelID) => {
    const level = allLevels.find((l) => l.id === id)
    if (!level) return console.log("Could not find level", { id, allLevels })

    setSelectedLevelId(id)
    pixelCtx.ueCommands.emitUIInteraction({
      type: "change_level",
      ...level
    })
  }

  useEffect(() => {
    pixelCtx.responseEmitter.on("Level_Loading_Started", () => setIsLoadingLevel(true))
    pixelCtx.responseEmitter.on("Level_Loading_Completed", () => setIsLoadingLevel(false))
    pixelCtx.responseEmitter.on("Level_Streaming_Started", () => setIsStreamingLevel(true))
    pixelCtx.responseEmitter.on("Level_Streaming_Completed", () => setIsStreamingLevel(false))
  }, [])

  const value = {
    allLevels,
    exterior,
    interior,
    selectedLevel,
    changeLevel,
    isLoadingLevel,
    isStreamingLevel
  }

  return <ShowroomLevelContext.Provider value={value}>{props.children}</ShowroomLevelContext.Provider>
}

const useShowroomLevels = (props: UseShowroomLevelsProps) => {
  const { units } = props

  const exterior: ExteriorShowroomLevel = useMemo(() => DEFAULT_EXTERIOR, [])
  const interior = useMemo<InteriorShowroomLevel[]>(() => {
    const templateUnits = units.filter((u) => TEMPLATE_UNIT_IDS.has(u.id))
    const interiorLevels = templateUnits.map((unit, i) => {
      const id: InteriorLevelID = `interior_${i}`
      const kind = LEVEL_KIND_INTERIOR
      return { id, kind, unit }
    })
    return interiorLevels
  }, [units])

  const all: ShowroomLevel[] = useMemo(() => {
    return [exterior, ...interior]
  }, [exterior, interior])

  return { interior, exterior, all }
}
