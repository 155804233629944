import React, { PropsWithChildren, useContext, useEffect } from "react"

import { FavoriteIdentifier, useFavoritesReducer } from "../hooks/useFavoritesReducer"
import { ShowroomSessionContext } from "./ShowroomSessionContext"

export interface IFavoritesContext {
  favorites: ReturnType<typeof useFavoritesReducer>["favorites"]
  toggleFavorite: ReturnType<typeof useFavoritesReducer>["toggleFavorite"]
}

const DEFAULT_VALUE: IFavoritesContext = {
  favorites: new Set<FavoriteIdentifier>(),
  toggleFavorite: () => false
}

export const FavoritesContext = React.createContext<IFavoritesContext>(DEFAULT_VALUE)

export const FavoritesContextProvider = (props: PropsWithChildren<unknown>): JSX.Element => {
  const sessionCtx = useContext(ShowroomSessionContext)
  const { favorites, toggleFavorite } = useFavoritesReducer()

  useEffect(() => {
    sessionCtx.setFavorites(favorites)
  }, [favorites])

  const value = {
    favorites,
    toggleFavorite
  }

  return <FavoritesContext.Provider value={value}>{props.children}</FavoritesContext.Provider>
}
