import React, { PropsWithChildren, useContext, useState } from "react"

import { v4 } from "uuid"

import { noop } from "../contexts"
import { Window } from "./Window"
import update from "immutability-helper"
import styled from "styled-components"
import { PixelStreamingContext } from "../contexts/PixelStreamingContext"

const WindowViewport = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`

export type WindowPosition = {
  top: number
  left: number
}

export type WindowSizing = {
  width: number
  height: number
}

export type AddWindowParams<Family extends string> = {
  family: Family
  title: string
  sizing: WindowSizing
  resizable?: boolean
  strategy?: "stack"
  position?: {
    top: number
  }
}

export type CloseWindowParams = {
  id: string
}

export type RenderContentParams<Family extends string> = {
  window: IWindow<Family>
}
export type RenderContentFunc<Family extends string> = (props: RenderContentParams<Family>) => JSX.Element
export type RenderContentMap<Family extends string> = {
  [K in Family]: RenderContentFunc<Family>
}

export type RenderWindowMap<Family extends string> = {
  [K in Family]: RenderContentFunc<Family>
}

export type IWindow<Family extends string> = {
  id: string
  family: Family
  title: string
  position: WindowPosition
  resizable?: boolean
  renderContent: RenderContentFunc<Family>
  sizing: WindowSizing
  data: unknown
}

export type MoveWindowParams = {
  id: string
  left: number
  top: number
}

export type AddWindowFunction<Family extends string> = (params: AddWindowParams<Family>, data?: unknown) => void

export interface IWindowManagerContext<Family extends string> {
  addWindow: AddWindowFunction<Family>
  closeWindow: (params: CloseWindowParams) => void
  closeAll: () => void
  windows: WindowMap<Family>
}

export type WindowMap<Family extends string> = {
  [id: string]: IWindow<Family>
}

const createDefaultWindowManagerContext = <Family extends string>(): IWindowManagerContext<Family> => {
  return {
    addWindow: noop,
    closeWindow: noop,
    closeAll: noop,
    windows: {}
  }
}

export const createWindowManagerContext = <Family extends string>(): React.Context<IWindowManagerContext<Family>> => {
  const defaultVal = createDefaultWindowManagerContext<Family>()
  return React.createContext<IWindowManagerContext<Family>>(defaultVal)
}

export type IWindowManagerContextProvider<Family extends string> = React.ReactElement<
  React.Provider<IWindowManagerContext<Family>>
>

export type WindowManagerContextProviderProps<Family extends string> = PropsWithChildren<{
  context: React.Context<IWindowManagerContext<Family>>
  renderWindows: RenderWindowMap<Family>
}>

export const WindowManagerContextProvider = <Family extends string>(
  props: WindowManagerContextProviderProps<Family>
): IWindowManagerContextProvider<Family> => {
  const pixelCtx = useContext(PixelStreamingContext)
  const [windows, setWindows] = useState<WindowMap<Family>>({})

  const emitWindowEvent = (event: "open_window" | "close_window", window: IWindow<Family>) => {
    const { family, id } = window
    pixelCtx.ueCommands.emitUIInteraction({
      type: event,
      window_type: family,
      window_id: id
    })
  }

  const addWindow: AddWindowFunction<Family> = (params, data) => {
    setWindows((current) => {
      const { family, title, sizing, position: defaultPos, strategy, resizable } = params

      if (strategy !== "stack") {
        if (Object.values(current).find((val) => val.family === family)) {
          return current
        }
      }

      const id = v4()
      const deltaPos = Object.keys(current).length * 20 + 20
      const position = defaultPos ? { top: defaultPos.top, left: 20 } : { top: deltaPos, left: deltaPos }
      const renderContent = props.renderWindows[family]
      const newWindow: IWindow<Family> = { id, family, position, title, renderContent, sizing, data, resizable }
      emitWindowEvent("open_window", newWindow)
      return update(current, {
        $merge: { [id]: newWindow }
      })
    })
  }

  const closeWindow = (params: CloseWindowParams) => {
    setWindows((current) => {
      const window = current[params.id]
      emitWindowEvent("close_window", window)
      return update(current, {
        $unset: [params.id]
      })
    })
  }

  const closeAll = () => {
    setWindows((current) => {
      for (const window of Object.values(current)) {
        emitWindowEvent("close_window", window)
      }

      return {}
    })
  }

  const value = {
    addWindow,
    closeWindow,
    closeAll,
    windows
  }

  return (
    <props.context.Provider value={value}>
      <WindowViewport>
        {props.children}
        {Object.keys(windows).map((id) => {
          const window = windows[id]
          return <Window key={window.id} window={window} context={props.context} />
        })}
      </WindowViewport>
    </props.context.Provider>
  )
}
