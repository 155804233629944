import React, { useContext, useEffect, useState } from "react"
import styled, { css } from "styled-components"
import { PixelStreamingContext } from "../../contexts/PixelStreamingContext"

export type CustomizationOption = {
  id: string
  title: string
  isAddOn?: boolean
}

const CUSTOMIZATION_OPTIONS: readonly CustomizationOption[] = [
  { id: "felt", title: "Feutré" },
  { id: "contrast", title: "Contraste" },
  { id: "monochrome", title: "Monochrome" },
  { id: "minimal", title: "Épuré" }
] as const

const CUSTOMIZATION_ADD_ONS: readonly CustomizationOption[] = [{ id: "plus", title: "Option +", isAddOn: true }] as const

const Container = styled.div`
  background-color: rgba(6, 74, 95, 1);
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const CustomizationItem = styled.div<{ selected?: boolean }>`
  display: flex;
  justify-content: center;
  padding: 8px;
  border-radius: 8px;
  font-weight: 700;
  cursor: pointer;

  ${(props) => css`
    background-color: ${props.selected ? "#FF8137" : "#f4eeea"};
    color: ${props.selected ? "white" : "#0d4657"};
  `}
`

const InteriorCustomization = (): JSX.Element => {
  const pixelCtx = useContext(PixelStreamingContext)
  const [selectedOpt, setSelectedOpt] = useState<string>(CUSTOMIZATION_OPTIONS[0].id)
  const [addOns, setAddOns] = useState<Set<string>>(new Set())

  const handleOptionClick = (optId: string) => {
    setSelectedOpt(optId)
    setAddOns(new Set())
  }

  const handleAddOnClick = (optId: string) => {
    setAddOns((prev) => {
      const newSet = new Set(prev)
      if (addOns.has(optId)) newSet.delete(optId)
      else newSet.add(optId)
      return newSet
    })
  }

  useEffect(() => {
    pixelCtx.ueCommands.emitUIInteraction({
      type: "customization_changed",
      customization: {
        selected_option: selectedOpt,
        plus_option_selected: !!addOns.size
      }
    })
  }, [selectedOpt, addOns])

  return (
    <Container>
      {CUSTOMIZATION_OPTIONS.map((opt) => (
        <CustomizationItem key={opt.id} selected={selectedOpt === opt.id} onClick={() => handleOptionClick(opt.id)}>
          {opt.title}
        </CustomizationItem>
      ))}
      {CUSTOMIZATION_ADD_ONS.map((opt) => (
        <CustomizationItem key={opt.id} selected={addOns.has(opt.id)} onClick={() => handleAddOnClick(opt.id)}>
          {opt.title}
        </CustomizationItem>
      ))}
    </Container>
  )
}

export default InteriorCustomization
