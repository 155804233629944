import React, { useCallback, useContext, useEffect, useMemo } from "react"
import styled from "styled-components"
import { Dock } from "../Dock"
import { useDockItems } from "./useDockItems"
import { PixelStreamingContext } from "../../contexts/PixelStreamingContext"
import { CRMContext } from "../../contexts/CRMContext"
import Modal from "../Modal"
import { ShowroomSessionContext } from "../../contexts/ShowroomSessionContext"
import { NEW_WINDOW, ShowroomWindowContext } from "../../contexts/ShowroomWindowContext"
import { CircularProgress } from "@mui/material"
import { ShowroomLevelContext } from "../../contexts/ShowroomLevelContext"
import { FavoritesContext } from "../../contexts/FavoritesContext"

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`

const Viewport = styled.div`
  background-size: cover;
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: calc(100vh - 20px);
  overflow: hidden;
`

const VideoContainer = styled.video`
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
`

const LoadingOverlayContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 30000;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const LoadingOverlay = (): JSX.Element => {
  return (
    <LoadingOverlayContainer>
      <CircularProgress color="inherit" />
      <div>Loading...</div>
    </LoadingOverlayContainer>
  )
}

const transformUnitStatus = (status: string): string => {
  // console.log("switching on status", status)

  switch (status) {
    case "Disponible":
      return "available"
    case "Réservé":
      return "reserved"
    case "Vendu":
      return "sold"
    default:
      return "unavailable"
  }
}

const Showroom = (): JSX.Element => {
  const crmCtx = useContext(CRMContext)
  const windowCtx = useContext(ShowroomWindowContext)
  const sessionCtx = useContext(ShowroomSessionContext)
  const pixelCtx = useContext(PixelStreamingContext)
  const levelCtx = useContext(ShowroomLevelContext)
  const favoritesCtx = useContext(FavoritesContext)
  const dockItems = useDockItems()

  const sendUnitList = useCallback(() => {
    if (!crmCtx.data.units?.length) return

    pixelCtx.ueCommands.emitUIInteraction({
      type: "unit_list",
      units: crmCtx.data.units.map((u) => {
        const status = transformUnitStatus(u.status)

        return {
          id: u.id,
          category: u.category,
          floor: u.floor,
          surface: u.surface,
          phase: u.phase,
          price: u.price,
          status
        }
      })
    })
  }, [crmCtx.data.units])

  useEffect(() => {
    if (pixelCtx.isDataChannelOpen) {
      sendUnitList()
    }
  }, [pixelCtx.isDataChannelOpen, sendUnitList])

  const showsLoading = useMemo(() => {
    return levelCtx.isLoadingLevel || levelCtx.isStreamingLevel
  }, [levelCtx.isLoadingLevel, levelCtx.isStreamingLevel])

  const showPlan = useCallback(
    (event: { data: { key: string } }) => {
      const { key } = event.data
      const unit = crmCtx.maps.units.get(key)
      if (unit) {
        windowCtx.addWindow({ ...NEW_WINDOW.plan_viewer, title: `Unit ${unit.id}` }, unit)
      }
    },
    [crmCtx.maps.units]
  )

  useEffect(() => {
    pixelCtx.responseEmitter.addListener("show_plan", showPlan)
    return () => {
      pixelCtx.responseEmitter.removeListener("show_plan", showPlan)
    }
  }, [showPlan, pixelCtx.responseEmitter])

  return (
    <Wrapper>
      <Viewport ref={pixelCtx.playerRef}>
        <VideoContainer id="uevideo" ref={pixelCtx.videoRef} playsInline={true} autoPlay={true} muted={true} />
        {showsLoading && <LoadingOverlay />}
      </Viewport>
      <Dock items={dockItems} disabled={showsLoading} />
      <Modal
        title="Sortie"
        open={sessionCtx.isExiting}
        actions={[
          {
            title: "Annuler",
            intent: "secondary",
            onClick: () => sessionCtx.setIsExiting(false)
          },
          {
            title: "Sortir",
            intent: "negative",
            onClick: () => {
              pixelCtx.ueCommands.emitUIInteraction({
                type: "reset_scene"
              })
              windowCtx.closeAll()
              sessionCtx.endSession()
            }
          }
        ]}
      >
        <p>Êtes vous sûr de vouloir sortir?</p>
      </Modal>
    </Wrapper>
  )
}

export default Showroom
