import styled from "styled-components"

export const InputLabel = styled.label<{ inverted?: boolean; disabled?: boolean }>`
  font-size: 14px;
  font-weight: 500;
  color: ${(props) => {
    const opacity = props.disabled ? 0.3 : 0.75
    return props.inverted ? `rgba(0, 0, 0, ${opacity})` : `rgba(255, 255, 255, ${opacity})`
  }};
`
