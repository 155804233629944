import React, { useContext } from "react"

import Showroom from "../components/Showroom"
import { WindowManager } from "../WindowManager/WindowManager"
import { RENDER_MAP, ShowroomWindowContext, ShowroomWindows } from "../contexts/ShowroomWindowContext"

import { ShowroomLevelContextProvider } from "../contexts/ShowroomLevelContext"
import { PixelStreamingContext, PixelStreamingContextProvider } from "../contexts/PixelStreamingContext"
import { UnitFiltersContextProvider } from "../contexts/UnitFiltersContext"
import { ShowroomSessionContext } from "../contexts/ShowroomSessionContext"
import { NewSessionPage } from "./NewSessionPage"
import { FavoritesContextProvider } from "../contexts/FavoritesContext"

const ShowroomContent = (): JSX.Element => {
  const pixelCtx = useContext(PixelStreamingContext)
  const sessionCtx = useContext(ShowroomSessionContext)

  const showNewSession = !pixelCtx.isDataChannelOpen || !sessionCtx.currentSession

  return (
    <>
      {showNewSession && <NewSessionPage />}
      <WindowManager<ShowroomWindows> context={ShowroomWindowContext} renderWindows={RENDER_MAP}>
        <Showroom />
      </WindowManager>
    </>
  )
}

const ShowroomPage = (): JSX.Element => {
  return (
    <PixelStreamingContextProvider>
      <FavoritesContextProvider>
        <UnitFiltersContextProvider>
          <ShowroomLevelContextProvider>
            <ShowroomContent />
          </ShowroomLevelContextProvider>
        </UnitFiltersContextProvider>
      </FavoritesContextProvider>
    </PixelStreamingContextProvider>
  )
}

export default ShowroomPage
