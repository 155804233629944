import React, { PropsWithChildren, useCallback, useEffect, useState } from "react"
import { z } from "zod"
import { asyncNoop } from "./index"
import { SalespeopleSchema } from "./CRMContext"

import {
  useRecordSessionEndMutation,
  useRecordSessionStartMutation,
  useSendFavoritesMutation,
  useUpdateSessionMutation
} from "../generated/graphql"
import { v4 } from "uuid"
import { noop } from "lodash"
import { FavoriteIdentifier } from "../hooks/useFavoritesReducer"

export type SalesPerson = z.infer<typeof SalespeopleSchema>

export type LeadInfo = {
  email: string
  telephone: string
  first_name: string
  last_name: string
}

export type ShowroomSession = {
  lead_session_id: string
  lead: LeadInfo
  salesperson: SalesPerson
  note: string
  favorites: string[]
  send_email: boolean
}

export const StartSessionParamsSchema = z.object({
  lead: z.object({
    email: z.string().email(),
    telephone: z.string(),
    first_name: z.string(),
    last_name: z.string()
  }),
  salesperson: SalespeopleSchema,
  note: z.string()
})

export type StartSessionParams = z.infer<typeof StartSessionParamsSchema>
export type StartSessionFunc = (params: StartSessionParams | null) => Promise<void>
export type EndSessionFunc = () => Promise<void>

export interface IShowroomSessionContext {
  currentSession: ShowroomSession | null
  startSession: StartSessionFunc
  endSession: EndSessionFunc
  isExiting: boolean
  setIsExiting: (val: boolean) => void
  sendFavoritesEmail: () => Promise<void>
  setFavorites: React.Dispatch<React.SetStateAction<Set<FavoriteIdentifier>>>
}

const DEFAULT_VALUE: IShowroomSessionContext = {
  currentSession: null,
  startSession: asyncNoop,
  endSession: asyncNoop,
  isExiting: false,
  setIsExiting: noop,
  sendFavoritesEmail: asyncNoop,
  setFavorites: (s) => s
}

const DEFAULT_GUEST_SESSION: ShowroomSession = {
  lead_session_id: "guest",
  lead: {
    email: "guest@guest.com",
    first_name: "Guest",
    last_name: "Guest",
    telephone: "123456789"
  },
  salesperson: {
    email: "guest@guest.com",
    id: 0,
    first_name: "Guest",
    last_name: "Guest",
    telephone: ""
  },
  note: "",
  favorites: [],
  send_email: false
}

export const ShowroomSessionContext = React.createContext<IShowroomSessionContext>(DEFAULT_VALUE)

export const ShowroomSessionContextProvider = (props: PropsWithChildren<unknown>): JSX.Element => {
  const [currentSession, setCurrentSession] = useState<ShowroomSession | null>(null)
  const [favorites, setFavorites] = useState<Set<FavoriteIdentifier>>(new Set())

  const [recordSessionStart] = useRecordSessionStartMutation()
  const [recordSessionEnd] = useRecordSessionEndMutation()
  const [updateSession] = useUpdateSessionMutation()
  const [sendFavorites] = useSendFavoritesMutation()

  const [isExiting, setIsExiting] = useState(false)

  const isGuest = () => currentSession?.lead_session_id === "guest"

  const startSession = useCallback<StartSessionFunc>(
    async (params) => {
      if (params !== null) {
        const parsed = StartSessionParamsSchema.parse(params)
        const session = {
          lead_session_id: v4(),
          lead: parsed.lead,
          salesperson: parsed.salesperson,
          note: parsed.note,
          favorites: [],
          send_email: true
        }

        await recordSessionStart({ variables: { input: session } })
        setCurrentSession(session)
      } else {
        setCurrentSession(DEFAULT_GUEST_SESSION)
      }
    },
    [recordSessionStart]
  )

  const endSession = useCallback<EndSessionFunc>(async () => {
    if (!currentSession) return

    if (!isGuest()) {
      const session = {
        ...currentSession,
        favorites: Array.from(favorites)
      }

      await recordSessionEnd({ variables: { input: session } })
    }
    setCurrentSession(null)
    setIsExiting(false)
  }, [currentSession, recordSessionEnd])

  const sendFavoritesEmail = useCallback(async () => {
    if (!currentSession || isGuest()) return
    await sendFavorites({ variables: { input: { lead_session_id: currentSession.lead_session_id } } })
  }, [sendFavorites, currentSession])

  const updateSessionFavorites = useCallback(async () => {
    if (!currentSession || isGuest()) return
    const session: ShowroomSession = {
      ...currentSession,
      favorites: Array.from(favorites)
    }
    await updateSession({ variables: { input: session } })
  }, [currentSession, favorites])

  useEffect(() => {
    if (isGuest()) return
    if (!currentSession?.favorites || favorites.size === 0) {
      return
    }

    updateSessionFavorites()
  }, [favorites, currentSession, updateSessionFavorites])

  const value = {
    currentSession,
    startSession,
    endSession,
    isExiting,
    setIsExiting,
    setFavorites,
    sendFavoritesEmail
  }

  return <ShowroomSessionContext.Provider value={value}>{props.children}</ShowroomSessionContext.Provider>
}
