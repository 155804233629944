import React, { PropsWithChildren } from "react"

import { IWindowManagerContext, RenderWindowMap, WindowManagerContextProvider } from "./WindowManagerContext"

export interface WindowManagerProps<WindowFamily extends string> {
  context: React.Context<IWindowManagerContext<WindowFamily>>
  renderWindows: RenderWindowMap<WindowFamily>
}

export const WindowManager = <Family extends string>(props: PropsWithChildren<WindowManagerProps<Family>>): JSX.Element => {
  return (
    <WindowManagerContextProvider renderWindows={props.renderWindows} context={props.context}>
      {props.children}
    </WindowManagerContextProvider>
  )
}

export default WindowManager
