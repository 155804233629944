export enum Scene {
  dev = "vivenda-dev",
  local = "vivenda-local",
  cloud = "vivenda"
}

export type ServerOption = {
  scene_id: Scene
  display_name: string
  dev_only: boolean
}

export const SERVER_OPTIONS: ServerOption[] = [
  {
    scene_id: Scene.dev,
    display_name: "Development",
    dev_only: true
  },
  {
    scene_id: Scene.cloud,
    display_name: "Cloud",
    dev_only: false
  },
  {
    scene_id: Scene.local,
    display_name: "Local",
    dev_only: false
  }
]
